@import "../../Styles/typography.scss";
@import "../../Styles/Color.scss";

@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

#root {
  // overflow-x: hidden;
}

// .css-1uccc91-singleValue{
//   display: none !important;
// }

.back_to_top_btn {
  display: none;
  cursor: pointer;

  .top_btn {
    position: fixed;
    right: 61px;
    bottom: 28px;
    background-color: white;
    width: 60px;
    height: 60px;
    z-index: 99 !important;
    border-radius: 50px;
    transform: scale(0.8);
    animation: scal01 3s alternate infinite;
    transition: 1s !important;

    &:hover {
      background-color: $Active_Button_Color;
    }

    img {
      padding: 14px 14px;
    }
  }
}

.landing_page_scroll.back_to_top_btn {
  display: block;
}

.landing_header_scroll.landing-header .header-alignment .logo img {
  width: 145px;
  height: 45px;
  object-fit: contain;
  transition: 1s !important;
}

.Common_Card_Main_Container {
  .no-class-found {
    text-align: center;
    color: #8484a0;
    font-family: GothamRounded-Medium;
    font-size: 18px;
    text-align: center;
  }
}

.Blue_Back_Container.Blue_Back_card_section
  .Common_Card_Main_Container
  .Common_Card_Container {
  margin: auto auto 10px auto !important;
}

.landing_header_scroll.landing-header
  .header-alignment
  .menu
  nav
  ul
  li
  a.active_nav_Side {
  width: 195px;
  height: 53px;
}

.landing_header_scroll.landing-header .header-alignment .menu nav ul li a {
  font-size: 16px;
}

.landing_header_scroll.landing-header
  .header-alignment
  .menu
  nav
  ul
  li:last-child
  a {
  width: 195px;
  height: 53px;
}

.landing_header_scroll.landing-header {
  padding: 10px;
}

.Star1 {
  animation: blinker 1.7s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.Star2 {
  animation: blinker2 1.3s cubic-bezier(1, 0, 0, 1) infinite alternate;
}

@keyframes blinker2 {
  to {
    opacity: 0;
  }
}

.Star3 {
  animation: blinker3 1s ease-in-out infinite alternate;
}

@keyframes blinker3 {
  to {
    opacity: 0;
  }
}

.Star4 {
  animation: blinker 1.3s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.Star5 {
  animation: blinker 1.7s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.Star8 {
  animation: blinker 1.7s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

.Star9 {
  animation: blinker2 1.3s cubic-bezier(1, 0, 0, 1) infinite alternate;
}

@keyframes blinker2 {
  to {
    opacity: 0;
  }
}

.Star6 {
  animation: blinker3 1s ease-in-out infinite alternate;
}

@keyframes blinker3 {
  to {
    opacity: 0;
  }
}

.Star7 {
  animation: blinker3 1s ease-in-out infinite alternate;
}

@keyframes blinker3 {
  to {
    opacity: 0;
  }
}

@keyframes fly {
  0% {
    transform: translateY(29vh);
  }

  100% {
    transform: translateY(-95vh);
  }
}

@keyframes moverleft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@keyframes spinnerRotatefour {
  0% {
    -webkit-transform: rotate(360deg) translateX(150px);
    transform: rotate(360deg) translateX(150px);
  }

  100% {
    -webkit-transform: rotate(0deg) translateX(150px);
    transform: rotate(0deg) translateX(150px);
  }
}

@keyframes zoom-in-zoom-out {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes scal01 {
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    border-radius: 100%;
    transition: 1s;
    box-shadow: -10.927px 13.023px 72px 0px rgb(222 161 79 / 44%);
  }
}

@keyframes scal02 {
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 100%;
    transition: 1s;
    box-shadow: -10.927px 13.023px 72px 0px rgb(22 54 67 / 44%);
  }
}

@keyframes scal03 {
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    border-radius: 100%;
    transition: 1s;
    box-shadow: -10.927px 13.023px 72px 0px rgb(196 132 56 / 44%);
  }
}

@keyframes zoomin-zoomout {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

// img.Blue_Wawe {
//   width: 203px;
//   height: 20px;
//   object-fit: contain;
//   position: relative;
//   top: 3px;
// }

.Carousel_Main_Container {
  .slick-next:before {
    content: "";
  }

  .slick-prev :before {
    content: "";
  }
}

.slick-next {
  position: absolute;
  right: -30px;
  z-index: 99;
}

.slick-prev {
  position: absolute;
  left: -50px;
  z-index: 99;
}

// .css-vps8j1-indicatorContainer {
//   width: 0px !important;
//   padding-right: 25px !important;
// }
.css-2613qy-menu {
  font-size: 5px;
  width: 170px;
}

.Responsive_front_Header_Container {
  background-color: #31bbda !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 7%;
  height: 66px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;

  .showMenu {
    z-index: 999;
    cursor: pointer;
  }

  .Header_logo {
    z-index: 999;
    width: 100%;
    display: flex;
    left: 0;

    a {
      margin: auto;
    }
  }

  .nav-text {
    padding: 27px 0px 33px 25px;
  }

  .navbar-toggle {
    background-color: #31bbda;
  }

  .nav-menu-educator {
    background-color: #31bbda;
  }

  .login {
    z-index: 999;
    height: 50px;

    .Login2 {
      display: none;
      margin: auto;
    }

    img {
      position: relative;
      bottom: 37px;
      left: 13px;
    }
  }

  .Link_responsive {
    color: rgb(255, 255, 255);
    margin: 5px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }

  .Border {
    border-top: 3px solid #fff;
    padding-top: 20px;
  }

  // .Profile_DropDown {
  //   display: flex;
  //   position: relative;
  //   background-color: #fff5e3;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 50px;
  //   cursor: pointer;
  //   width: fit-content;
  //   margin: 0px 10px;
  //   margin-left: 25px;
  //   margin-top: 10px;
  //   .Profile {
  //     width: 47px;
  //     height: 47px;
  //     border-radius: 50px;
  //     border: 3px solid #f2cb72;
  //   }
  //   .DropDownIconContainer {
  //     padding: 10px 15px 10px 25px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border-radius: 0px 60px 60px 0px;
  //     .DropDownIcon {
  //       width: 15px;
  //     }
  //   }
  // }
}

.Landing_Page_Background {
  .SavedClass_Container {
    overflow: inherit;
  }

  // .Responsive_front_Header_Container {
  //   background-color: #31bbda !important;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 36px 5%;
  //   height: 66px;
  //   position: fixed;
  //   top: 0;
  //   z-index: 999;
  //   width: 100%;
  //   .showMenu {
  //     z-index: 999;
  //     cursor: pointer;
  //   }
  //   .Header_logo {
  //     z-index: 999;
  //     width: 100%;
  //     display: flex;
  //     left: 0;
  //     a {
  //       margin: auto;
  //     }
  //   }
  //   .nav-text {
  //     padding: 27px 0px 33px 25px;
  //   }
  //   .navbar-toggle {
  //     background-color: #31bbda;
  //   }
  //   .nav-menu-educator {
  //     background-color: #31bbda;
  //   }
  //   .login {
  //     z-index: 999;
  //     height: 50px;
  //     .Login2 {
  //       display: none;
  //       margin: auto;
  //     }
  //     img {
  //       position: relative;
  //       bottom: 37px;
  //       left: 13px;
  //     }
  //   }
  //   .Link_responsive {
  //     color: rgb(255, 255, 255);
  //     margin: 5px 25px;
  //     cursor: pointer;
  //     display: flex;
  //     align-items: center;
  //     img {
  //       margin-right: 15px;
  //     }
  //   }
  //   .Border {
  //     border-top: 3px solid #fff;
  //     padding-top: 20px;
  //   }
  //   // .Profile_DropDown {
  //   //   display: flex;
  //   //   position: relative;
  //   //   background-color: #fff5e3;
  //   //   align-items: center;
  //   //   justify-content: center;
  //   //   border-radius: 50px;
  //   //   cursor: pointer;
  //   //   width: fit-content;
  //   //   margin: 0px 10px;
  //   //   margin-left: 25px;
  //   //   margin-top: 10px;
  //   //   .Profile {
  //   //     width: 47px;
  //   //     height: 47px;
  //   //     border-radius: 50px;
  //   //     border: 3px solid #f2cb72;
  //   //   }
  //   //   .DropDownIconContainer {
  //   //     padding: 10px 15px 10px 25px;
  //   //     display: flex;
  //   //     align-items: center;
  //   //     justify-content: center;
  //   //     border-radius: 0px 60px 60px 0px;
  //   //     .DropDownIcon {
  //   //       width: 15px;
  //   //     }
  //   //   }
  //   // }
  // }
}

.Landing_Page_Background {
  background: #ebecf5;
  position: relative;
  width: 100vw;
  overflow-x: hidden;
}

.landing-header.landing_header_scroll {
  background: #31bbda;
}

.star {
  position: absolute;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0) red;
  border-radius: 50px;
  z-index: -1;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

@keyframes twinkle {
  0% {
    transform: scale(1, 1);
    background: rgba(240, 199, 34, 0);
    animation-timing-function: ease-in;
  }

  60% {
    transform: scale(0.8, 0.8);
    background: rgba(240, 199, 34, 1);
    animation-timing-function: ease-out;
  }

  80% {
    background: rgba(240, 199, 34, 0);
    transform: scale(1, 1);
  }

  100% {
    background: rgba(240, 199, 34, 0);
    transform: scale(1, 1);
  }
}

@keyframes mover {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50px);
  }
}

@keyframes moves {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.landing-header {
  background-color: $lightblue;
  padding: 20px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  border-bottom: 3px solid #8cd9ea;
  transition: 1s !important;

  .header-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
      width: 400px;
      // height: 400px;
      left: -65px;
      top: 53px;
      z-index: 999;
      position: absolute;
      border-radius: 50%;
      filter: drop-shadow(0px 0px 4px #22222241);
      transition: 1s !important;

      img {
        position: absolute;
        bottom: 25%;
        right: 20%;
        transition: 1s !important;
        width: 181px;
        height: 47px;
        object-fit: contain;
      }
    }

    .login {
      margin-right: 80px;

      .css-1wa3eu0-placeholder {
        color: #51bfd0 !important;
        font-size: 15px;
        margin-left: 12px;
      }

      .css-2b097c-container {
        position: relative;
        box-sizing: border-box;
        width: 178px;
      }

      .css-1uccc91-singleValue {
        overflow: initial !important;
        color: #51bfd0 !important;
      }

      .css-g1d714-ValueContainer {
        overflow: initial !important;
      }

      .css-vps8j1-indicatorContainer {
        width: 0px !important;
        padding-right: 25px !important;
      }

      .css-k7p11r-indicatorContainer {
        width: 0px !important;
        padding-right: 25px !important;
      }

      .css-1392reu-control {
        padding-left: 5px !important;
        height: 59px !important;
        width: 208px !important;
        max-height: initial;
      }

      .css-3xnrlj-control {
        padding-left: 5px !important;
        height: 59px !important;
        width: 208px !important;
        max-height: initial;
      }

      //   div {
      //     padding: 0 16px;
      //     cursor: pointer;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     border-radius: 8px;
      //     min-width: 100px;
      //     border: 1px solid transparent;
      //     height: 43px;
      //     background-color: $primary_White;
      //     @include heading-18;
      //     transition: 0.3s ease-in-out;
      //   }
    }

    .menu {
      // margin-left: 281px;
      margin-left: 0;

      nav {
        ul {
          display: flex;
          align-items: center;
          list-style: none;

          .last_child {
            border: 3px solid #4dc6e0;
            border-radius: 19px;
            display: none;
          }

          li:last-child a {
            background-color: #f0c722;
            border: none;
            box-shadow: 0px 0px 10px 2px rgb(202, 165, 14, 0.75) inset;
            margin: 3px;
            width: 208px;
            height: 59px;
            transition: 1s !important;

            &:hover {
              background-color: #f0c722;
            }
          }

          li {
            a {
              text-decoration: none;
              color: #fff;
              margin-right: 10px;
              // padding: 25px 33px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 15px;
              // min-width: 140px;
              border: 1px solid transparent;
              height: 43px;
              background-color: #06add3;
              border: 2px solid #06add3;
              @include heading-18;
              color: $primary_White;
              transition: 0.3s ease-in-out !important;
              background-color: #66cde3;
              border: 3px solid #9fe1ef;
              width: 183px;
              height: 59px;
              transition: 1s !important;

              &:hover {
                background-color: $Active_Button_Color !important;
                border: 3px solid $primary_White !important;
              }

              &.active_nav_Side {
                background-color: $Active_Button_Color;
                border: 3px solid $primary_White;
                width: 184px;
                height: 59px;
                transition: 1s !important;
              }

              border: 2px solid $primary_White;
            }
          }
        }
      }
    }
  }
}

.Landing_Page_Blue_Background
  .Center_Video_Player
  .Center_Video_Player_content
  .Card_Image_Container {
  width: 100%;
  height: 559px;
  margin: 0%;
  position: relative;
}

.Landing_Page_Blue_Background
  .Center_Video_Player
  .Center_Video_Player_content:hover
  .play_Btn {
}

.Card_Image_Container .Card_Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 37px 37px 0 0;
}

.Center_Video_Player_content .Card_Image_Container .play_Btn {
  .fa.fa-play {
    position: absolute;
    top: 50%;
    right: 44%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #ffffff;
    font-size: 32px;
    z-index: 999;
    border: 3px solid white;
  }

  .fa.fa-pause {
    position: absolute;
    top: 50%;
    right: 44%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #ffffff;
    font-size: 32px;
    z-index: 999;
    border: 3px solid white;
  }

  i {
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Landing_Page_Blue_Background {
  // background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/Background.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: $lightblue;
  width: 100%;
  height: 900px;
  z-index: 99;
  padding-top: 350px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/banner-shap.png");
    bottom: 0;
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    z-index: 2;
  }

  .Center_Video_Player {
    // overflow: hidden;
    // height: 568px;
    width: 1145px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80px -30px;
    margin: auto;
    position: relative;
    bottom: -9%;
    // border: 5px solid #ffffff;
    border-radius: 40px;
    // background-color: #08b0d8;
    z-index: 3;

    .Left_SIDE {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translate(10%, -50%);

      p {
        text-align: center;
        margin: 10px 0px;
        color: #fff;
      }
    }

    video.Card_Img {
      width: 100%;
      // height: 558px;
      // object-fit: unset;
      // object-fit: contain;
      border: 5px solid #ffffff;
      border-radius: 35px;
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      img {
        width: 50px;
      }
    }

    &::before {
      width: 150px;
      height: 80px;
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/vector.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 2%;
      left: -10%;
      content: "";
      z-index: 1;
      animation: mover 3s infinite alternate;
    }

    &::after {
      width: 150px;
      height: 80px;
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/vector.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 2%;
      right: -10%;
      content: "";
      animation: mover 3s infinite alternate;
    }

    .child-image {
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/Child.png");
      background-repeat: no-repeat;
      width: 324px;
      position: absolute;
      z-index: 1;
      top: -31%;
      left: 50%;
      height: 175px;
      transform: translateX(-50%);
    }

    .child-image::before {
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/left-side.png");
      background-repeat: no-repeat;
      width: 100%;
      position: absolute;
      height: 100%;
      content: "";
      top: 71%;
      background-size: 123px 115px;
      left: -9%;
    }

    .child-image-right::after {
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/right-side.png");
      background-repeat: no-repeat;
      width: 100%;
      position: absolute;
      height: 100%;
      content: "";
      top: 76%;
      right: -75%;
      background-size: 98px 121px;
    }

    .Center_Video {
      height: 450px;
      width: 100%;
      border-radius: 20px !important;

      iframe {
        width: 1066px;
        height: 447px;
        border-radius: 17px;
        cursor: pointer;
      }

      .react-player__preview {
        width: 1066px !important;
        height: 446px !important;
        background-size: cover;
        background-position: center center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/bus.png);
        border-radius: 17px;
      }
    }
  }

  .ytp-chrome-controls .ytp-play-button svg {
    width: 1030px !important;
    height: 1030px !important;
  }

  .react-player__shadow {
    background: none !important;
  }

  .react-player__play-icon {
    display: none;
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes whiterotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(360deg);
    }
  }

  .LEFT_SIDE_IMG {
    height: 100%;
    width: 50%;
    position: absolute;
    z-index: -1;
    // top: 0;
    bottom: 144px;

    .Earth {
      position: absolute;
      top: 50%;
      left: -10%;
      -webkit-animation: rotation 80s infinite linear;
      animation: rotation 80s infinite linear;
      object-fit: contain;
    }

    .Star1 {
      position: absolute;
      bottom: 20%;
      left: 30%;
      width: 25px;
    }

    .Star7 {
      position: absolute;
      bottom: 63%;
      left: 14%;
      width: 25px;
    }

    .Star8 {
      position: absolute;
      bottom: 8%;
      left: 5%;
      width: 25px;
    }

    .camera {
      position: absolute;
      bottom: 0%;
      left: 5%;
      object-fit: contain;
    }

    .Star2 {
      position: absolute;
      top: 40%;
      left: 30%;
      width: 20px;
    }

    .music {
      position: absolute;
      top: 40%;
      left: 40%;
      object-fit: contain;
    }

    .paint {
      position: absolute;
      top: 37%;
      left: 70%;
    }

    .Star3 {
      position: absolute;
      top: 40%;
      left: 65%;
      width: 20px;
    }

    .Sky_Left {
      position: absolute;
      top: 50%;
      right: 30%;
    }
  }

  .RIGHT_SIDE_IMG {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;

    .Rocket {
      position: absolute;
      left: 0;
      width: 100%;
      height: 424px;
      object-fit: scale-down;
      bottom: -106px;
      padding-left: 550px;
      -webkit-animation: fly 12s infinite alternate;
      animation: fly 12s infinite ease-in-out;
      // animation-duration: 6s;
      z-index: 1;
    }

    .book {
      position: absolute;
      top: 18%;
      right: 24%;
      width: 100%;
      height: 350px;
      object-fit: scale-down;
      -webkit-animation: moves 1s infinite alternate;
      animation: moves 1s infinite alternate;
    }

    .Star4 {
      position: absolute;
      top: 40%;
      right: 60%;
      width: 20px;
    }

    .Star5 {
      position: absolute;
      bottom: 0px;
      right: 5%;
      width: 20px;
    }

    .Star6 {
      position: absolute;
      bottom: 40%;
      right: 40%;
      width: 20px;
    }

    .Star9 {
      position: absolute;
      bottom: 16%;
      right: 23%;
      width: 20px;
    }

    .paint1 {
      position: absolute;
      top: 18%;
      right: 0;
      width: 100%;
      height: 350px;
      object-fit: scale-down;
    }

    .Right_Box {
      width: 280px;
      height: 163px;
      background-color: #31bbda;
      border: 3px solid #8cd9ea;
      border-radius: 20px;
      position: absolute;
      top: 34%;
      right: 5%;
      display: none;

      .Container {
        position: absolute;
        top: -30%;
        left: 50%;
        transform: translate(-50%, 0);

        p {
          text-align: center;
          margin-top: 10px;
          color: #fff;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }
}

.About_Meeko_Main_Container {
  z-index: 97;
  position: relative;
  // top: -250px;
  // padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GothamRounded-Medium;
  width: 100%;
  margin-top: -130px;
  padding-bottom: 100px;

  .Illustration_media {
    display: none;
  }

  .Center_Text {
    .Top_About {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #8484a0;
      margin-top: 218px;

      .Big_Had {
        font-size: 60px;
        margin: 5px 0px;
        line-height: 50px;
        text-align: center;
        font-family: Banda_Niera-Medium;
      }

      .Small_Had {
        font-size: 30px;
      }
    }

    .Orange_About_Box {
      margin-top: 60px;
      width: 90%;

      .Title {
        font-size: 28px;
        margin-bottom: 15px;
        color: #fe7646;
        font-family: Banda_Niera-Medium;
      }

      .Details {
        font-size: 18px;
        font-weight: 400;
        max-width: 600px;
        color: #8484a0;
        margin: 0 0 1.25rem 0;
      }

      .Rode_Container {
        display: flex;

        .Big_Rode {
          width: 143px;
          height: 9px;
          border-radius: 8px;
          background-color: #fe7646;
        }

        .Small_Rode {
          width: 32px;
          margin-left: 10px;
          opacity: 0.5;
          height: 9px;
          border-radius: 8px;
          background-color: #fe7646;
        }
      }
    }

    .Blue_Box {
      margin-left: auto;
      text-align: right;
      margin-top: 80px;

      .Title {
        color: #46d1fe;
      }

      .Rode_Container {
        display: flex;
        justify-content: flex-end;

        .Big_Rode {
          background-color: #46d1fe;
        }

        .Small_Rode {
          margin-right: 10px;
          background-color: #46d1fe;
        }
      }
    }

    .Pink_Box {
      margin-top: 100px;

      .Title {
        color: #fe44a5;
      }

      .Rode_Container {
        display: flex;

        .Big_Rode {
          background-color: #fe44a5;
        }

        .Small_Rode {
          margin-right: 10px;
          background-color: #fe44a5;
        }
      }
    }
  }

  .Big_Round_Left {
    background-color: #eff0f6;
    width: 565px;
    height: 555px;
    border-radius: 50%;
    position: absolute;
    top: -4%;
    left: -5%;
    // filter: drop-shadow(0px 0px 4px #22222241);
    box-shadow: 0px 4px 98.28px 18.72px rgba(0, 0, 0, 0.06);
  }

  .Illustration1 {
    width: 100%;
    height: 364px;
    object-fit: scale-down;
    position: absolute;
    right: -33%;
    top: 360px;
    filter: drop-shadow(0px 0px 0px white);
  }

  .white_Back {
    position: absolute;
    // top: 66%;
    top: 68%;
    left: 49%;
    z-index: -1;
    animation: whiterotate 30s infinite linear;
  }

  .Big_Round_Bottom_Left {
    background-color: #eff0f6;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    position: absolute;
    bottom: -10%;
    left: -10%;
    // filter: drop-shadow(0px 0px 4px #22222241);
    box-shadow: 0px 4px 98.28px 18.72px rgba(0, 0, 0, 0.06);

    .Illustration2 {
      width: 100%;
      height: 364px;
      object-fit: scale-down;
      position: absolute;
      right: -59%;
      // top: 57px;
      top: 10%;
    }

    .white_Back2 {
      position: absolute;
      left: 75%;
      // top: 21%;
      top: 17%;
      z-index: -1;
      animation: whiterotate 30s infinite linear;
    }
  }

  .Big_Round_Bottom_Right {
    background-color: #eff0f6;
    width: 268px;
    height: 500px;
    border-radius: 270px 0px 0px 270px;
    position: absolute;
    bottom: 20%;
    right: 0;
    // filter: drop-shadow(0px 0px 4px #22222241);
    box-shadow: 0px 4px 98.28px 18.72px rgba(0, 0, 0, 0.06);

    .Illustration3 {
      width: 395px;
      position: absolute;
      bottom: 8%;
      left: -89%;
      height: 359px;
      object-fit: scale-down;
    }

    .white_Back3 {
      position: absolute;
      right: 55%;
      // top: 30%;
      top: 25%;
      z-index: -1;
      animation: whiterotate 30s infinite linear;
    }
  }

  .child1 {
    position: absolute;
    top: 29%;
    right: 15%;
    width: 176px;
    height: 145px;
    object-fit: contain;
  }

  .Compass {
    position: absolute;
    top: 52%;
    left: 0%;
  }

  .Rocket1 {
    position: absolute;
    bottom: -43px;
    right: 10%;
    animation: moverleft 1.5s infinite alternate;
    width: 145px;
    height: 136px;
    object-fit: contain;
  }

  .Earth_Round_Bottom_Right {
    position: absolute;
    top: -2%;
    right: 0%;

    .earthBack {
      position: relative;
      bottom: -108px;
      display: block;
      width: auto;
      height: 381px;
    }

    .earth1 {
      position: absolute;
      top: 228px;
      animation: rotation 80s infinite linear;
      left: 95px;
      width: auto;
      height: 140px;
    }
  }
}

.Blue_Back_Container.popular_class_box_section .Top_Cont {
  top: 0;
  margin-top: 0;
  padding-top: 0;
}

.try_demo_class_card_section {
  .Common_Card_Container {
    .Card_Bottom_Container .Card_Bottom img {
      margin-right: 0 !important;
    }

    .Card_Image_Container {
      width: 100%;
      // height: 200px;
    }
  }
}

.Blue_Back_Container {
  background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/blue_Back.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 900px;

  .Top_Cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 26px;

    p {
      color: #8484a0;
      font-size: 60px;
      font-family: Banda_Niera-Medium;
      z-index: 99;
    }

    .Blue_Wawe {
      margin: 10px 0px;
      z-index: 1;
      width: 174px;
    }

    .white-flow {
      position: relative;
      top: -130px;
      animation: zoomin-zoomout 2s ease-out infinite;
    }

    .Free {
      position: absolute;
      top: 105px;
    }
  }

  .Card_Container {
    padding: 40px 30px;
    width: 90%;
    margin: auto;
    border: 3px solid #fff;
    background-color: #ffffff87;
    border-radius: 30px;
    margin-top: -169px;

    .slick-prev,
    .slick-next {
      &::before {
        display: none;
      }

      i {
        color: #05add2;
        font-size: 53px;
        background: white;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: -27px;
        padding: 4px 21px;
        font-weight: 900;
        transition: 0.2s;
      }

      i:hover {
        color: #ffffff;
        background: #31bbda;
        transition: 0.2s;
      }
    }

    i {
      font-size: 10px;
    }

    .slick-next {
      right: -15px;

      i {
        padding: 4px 25px;
      }
    }
  }

  .slick-prev {
    &::before {
      display: none;
    }

    i {
      color: #05add2;
      font-size: 53px;
      background: white;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: relative;
      left: 0;
      top: -27px;
      padding: 4px 21px;
      font-weight: 900;
    }
  }

  .popular {
    margin-top: 53px !important;
  }

  .Explore_More_Btn {
    margin: auto;
    padding: 15px 29px;
    background-color: #08b6db;
    border: 3px solid #fff;
    width: 200px;
    color: #fff;
    border-radius: 0px 0px 10px 10px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s !important;

    &:hover {
      background-color: $Active_Button_Color;
    }
  }
}

.popular_class_box_section {
  padding-top: 50px;
}

.Categories_media {
  display: none;
}

.Categories.Categories_head_section {
  padding-top: 70px;
}

.Categories {
  padding-top: 110px;
  position: relative;
  top: 0;

  .Paint_Bruch {
    position: absolute;
    bottom: 10%;
    left: 5%;
  }

  .shoes_boy {
    position: absolute;
    bottom: -80px;
    right: 7%;
  }

  .Round_red {
    position: absolute;
    top: -10%;
    right: 5%;
  }

  .Top_Cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -40px;

    p {
      color: #8484a0;
      font-size: 60px;
      font-family: Banda_Niera-Medium;
    }

    .Blue_Wawe {
      margin: 10px 0px;
    }
  }

  .book_polygon {
    position: absolute;
    right: 0;
    bottom: 79px;

    .Polygon1 {
      position: relative;
    }

    .book {
      position: absolute;
      left: 34px;
      top: 56px;
    }

    .Polygon2 {
      position: relative;
      bottom: 168px;
      left: -46px;
    }
  }

  .Hexagonal_Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;

    .Hexagonal_media {
      display: none;
    }

    .Hexa_Cont {
      position: relative;
      cursor: pointer;

      .Hexa_content_Cont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-70%, -60%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
          color: #fff;
          font-size: 20px;
          font-family: GothamRounded-Medium;
        }
      }

      .hexa_content {
        display: none;
      }
    }

    .Top_Hexagonal_Container {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      margin-top: -5%;
      flex-wrap: wrap;

      div {
        margin: 20px;
      }

      .Hexa_Cont_same {
        display: flex;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }

    .Top_Hexagonal_Container {
      display: block !important;
    }

    .Hexa_Cont:nth-child(5) {
      margin-left: -11% !important;
    }

    .Hexagonal_for_media {
      width: auto;
      float: left;
    }

    .Hexa_Cont {
      transform: rotateY(0deg);
      transition: 1s !important;
    }

    .Hexa_Cont:hover {
      transform: scale(0.9);
    }

    .Top_Hexagonal_Container {
      width: 90%;
      margin: 0 auto;
    }

    .Hexa_Cont_same {
      display: block !important;
    }

    .Hexa_Cont {
      float: left;
      width: 16%;
    }

    .Bottom_Hexagonal_Container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -7%;
      margin-left: 5%;
      flex-wrap: wrap;

      div {
        margin: 20px;
      }

      .Hexa_Cont_same {
        display: flex;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
}

// .Become_Meekonian_Cont {
//   position: relative;
//   .Top_Cont {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     position: relative;
//     padding-bottom: 25px;
//     top: 0;
//     p {
//       color: #8484a0;
//       font-size: 60px;
//       font-family: Banda_Niera-Medium;
//     }
//     .Blue_Wawe {
//       margin: 10px 0px;
//     }
//   }
//   .Round_Blue_Left {
//     border-radius: 50%;
//     background-color: #eeeff6;
//     width: 370px;
//     height: 420px;
//     border-radius: 50%;
//     position: absolute;
//     bottom: -25%;
//     left: -9%;
//     box-shadow: 0px 0px 42px 17px rgba(228, 230, 237, 0.9);
//   }
//   .Round_Blue_Right {
//     background-color: #eeeff6;
//     width: 220px;
//     height: 400px;
//     border-radius: 270px 0 0 270px;
//     position: absolute;
//     top: 21%;
//     right: 0;
//     box-shadow: 0px 0px 42px 17px rgba(228, 230, 237, 0.9);
//   }
//   .Become_Cont {
//     padding: 79px 21px;
//     width: 95%;
//     margin: auto;
//     border: 3px solid #f6f7fb;
//     background-color: #ebecf5;
//     border-radius: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .Content_Container_Main {
//       display: flex;
//       align-items: center;
//       justify-content: space-evenly;
//       flex-wrap: wrap;
//       width: 100%;
//       z-index: 0;
//       .Sub_Content_Container {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         width: 250px;
//         .Round_Cont {
//           width: 240px;
//           height: 240px;
//           background-color: #f4f5f9;
//           border: 2px solid #fff;
//           border-radius: 50%;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           img {
//             width: 150px;
//             height: 150px;
//             object-fit: scale-down;
//           }
//         }
//         p {
//           color: #8484a0;
//           font-family: GothamRounded-Medium;
//           font-size: 18px;
//           margin-top: 20px;
//           text-align: center;
//         }
//       }
//       .Next_arrow {

//         background-color: #f4f5f9;
//         width: 90px;
//         height: 106px;
//         position: relative;
//         top: -26px;
//         clip-path: polygon(
//           49% 9%,
//           100% 0,
//           91% 51%,
//           100% 100%,
//           49% 90%,
//           0 100%,
//           10% 49%,
//           0 0
//         );
//         img {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//         }
//       }
//     }
//   }
// }

.Why_Meeko_section {
  width: 100%;
  margin: auto;
}

.Why_Meeko {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 200px;

  .Background_Img {
    position: absolute;
    bottom: -10%;

    left: 0;
    width: 100%;
  }

  .Join_Btn {
    background-color: #f0c722;
    padding: 12px 20px;
    box-shadow: 0px 6px 9.7px 0.3px rgb(27 75 83 / 12%),
      inset 0 -6px 10px #0000001f;
    font-size: 20px;
    font-weight: 500;
    width: fit-content;
    margin: auto;
    margin-top: 30px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    z-index: 99;
    position: relative;
    display: none;
  }

  .Why1 {
    position: absolute;
    top: 20%;
    left: 10%;
    z-index: 100;
    height: 300px;
    object-fit: scale-down;
  }

  .whiteflow1 {
    position: absolute;
    left: 10%;
    top: 14%;
    animation: zoomin-zoomout 2s ease-out infinite;
  }

  .Why2 {
    position: absolute;
    top: 17%;
    right: 33%;
    z-index: 100;
    height: 300px;
    object-fit: scale-down;
  }

  .Why8 {
    position: absolute;
    top: 39%;
    left: 26%;
    animation: rotation 7s infinite linear;
  }

  .Why5 {
    position: absolute;
    bottom: 10%;
    left: 14%;
    z-index: 100;
    height: 260px;
    object-fit: scale-down;
    width: 235px;
  }

  .whiteflow2 {
    position: absolute;
    left: 13%;
    top: 62%;
    animation: zoomin-zoomout 2s ease-out infinite;
    width: 300px;
    height: 443px;
    object-fit: scale-down;
  }

  .Why11 {
    position: absolute;
    top: 63%;
    left: 38%;
  }

  .Why12 {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 72%;
    left: 7%;
  }

  .Why6 {
    position: absolute;
    bottom: 23%;
    right: 5%;
    z-index: 100;
    height: 257px;
    object-fit: scale-down;
    width: 300px;
  }

  .whiteflow3 {
    position: absolute;
    top: 54%;
    right: 4%;
    width: 300px;
    height: 400px;
    object-fit: scale-down;
    animation: zoomin-zoomout 2s ease-out infinite;
  }

  .Why7 {
    position: absolute;
    bottom: -9%;
    right: 12%;
    z-index: 100;
    height: 383px;
    object-fit: scale-down;
    width: 287px;
  }

  .Why13 {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 82%;
    right: 0%;
  }

  .Why3_Container {
    position: absolute;
    top: 31%;
    right: 8%;
    z-index: 100;
    border-radius: 50%;
    width: 330px;
    height: 330px;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 232.5px 17.5px rgb(51 65 195 / 6%);
    background-color: #ffffff82;

    .Why3 {
      width: 100%;
      height: 285px;
      object-fit: scale-down;
      margin-right: 22px;
    }

    .Why10 {
      position: absolute;
      left: -438px;
      bottom: -92px;
      width: 40px;
      height: 40px;
    }
  }

  .Why4_Container {
    position: absolute;
    top: 45%;
    left: 3%;
    z-index: 100;
    border-radius: 50%;
    width: 288px;
    height: 288px;
    border: 3px solid #fff;
    box-shadow: 0px 0px 232.5px 17.5px rgb(51 65 195 / 6%);
    background-color: #ffffff82;

    .Why4 {
      width: 100%;
      object-fit: scale-down;
      position: absolute;
      top: -1px;
      right: 18px;
    }

    .Why9 {
      position: absolute;
      left: 192px;
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
  }

  .Top_Cont {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    position: absolute;
    display: flex;
    width: 100%;
    right: 0;

    .why_meeko {
      z-index: 1;
    }
  }

  .white_flow2_box {
    .white-flow2 {
      position: relative;
      left: 279px;
      top: -43px;
      animation: zoomin-zoomout 2s ease-out infinite;
    }
  }

  .why_Meeko_Container {
    width: 800px;

    .Text_Container {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;

      p {
        font-size: 37px;
        font-family: Banda_Niera-Medium;
        text-align: center;
        color: #8484a0;
        filter: drop-shadow(0px 0px 4px #22222241);
        text-shadow:
          /* first layer at 1px */ -1px -1px 0px rgb(255, 255, 255),
          0px -1px 0px #fff, 1px -1px 0px #fff, -1px 0px 0px #fff,
          1px 0px 0px #fff, -1px 1px 0px #fff, 0px 1px 0px #fff,
          1px 1px 0px #fff, /* second layer at 2px */ -2px -2px 0px #fff,
          -1px -2px 0px #fff, 0px -2px 0px #fff, 1px -2px 0px #fff,
          2px -2px 0px #fff, 2px -1px 0px #fff, 2px 0px 0px #fff,
          2px 1px 0px #fff, 2px 2px 0px #fff, 1px 2px 0px #fff, 0px 2px 0px #fff,
          -1px 2px 0px #fff, -2px 2px 0px #fff, -2px 1px 0px #fff,
          -2px 0px 0px #fff, -2px -1px 0px #fff;
      }

      .Rode_Container {
        display: flex;
        margin-top: 30px;

        .Big_Rode {
          width: 143px;
          height: 9px;
          border-radius: 8px;
        }

        .Small_Rode {
          width: 32px;
          margin-left: 10px;
          opacity: 0.5;
          height: 9px;
          border-radius: 8px;
        }
      }
    }

    .Blue_Cont {
      .Rode_Container {
        .Big_Rode {
          background-color: #46d1fe;
        }

        .Small_Rode {
          background-color: #46d1fe;
        }
      }
    }

    .Pink_Cont {
      .Rode_Container {
        margin-right: 0px;

        .Big_Rode {
          background-color: #fe44a5;
        }

        .Small_Rode {
          background-color: #fe44a5;
        }
      }
    }

    .Yellow_Cont {
      .Rode_Container {
        margin-right: 0px;

        .Big_Rode {
          background-color: #e1b14a;
        }

        .Small_Rode {
          background-color: #e1b14a;
        }
      }
    }

    .Orange_Cont {
      margin-left: auto;

      p {
        text-align: center;
      }

      .Rode_Container {
        margin-left: 0px;

        .Big_Rode {
          background-color: #fe7646;
        }

        .Small_Rode {
          background-color: #fe7646;
          margin-right: 10px;
        }
      }
    }

    .Green_Cont {
      margin-left: auto;

      p {
        text-align: center;
      }

      .Rode_Container {
        margin-left: 0px;

        .Big_Rode {
          background-color: #63e14a;
        }

        .Small_Rode {
          background-color: #63e14a;
          margin-right: 10px;
        }
      }
    }

    .BluePink_Cont {
      margin-left: auto;

      p {
        text-align: center;
      }

      .Rode_Container {
        margin-left: 0px;

        .Big_Rode {
          background-color: #a179f1;
        }

        .Small_Rode {
          background-color: #a179f1;
          margin-right: 10px;
        }
      }
    }

    .Text_Container_03 {
      position: relative;
      left: -23%;
    }

    .Text_Container_02 {
      position: relative;
      right: -15%;
    }

    .Text_Container_04 {
      position: relative;
      right: -17%;
    }

    .Text_Container_06 {
      position: relative;
      right: 0%;
      margin-top: 120px;
    }
  }
}

.Banner_Container {
  margin-top: 100px;
  width: 100%;

  .Banner_Btn_Group {
    align-items: center;
    display: flex;
    justify-content: center;

    div {
      background-color: $yellow_dark;
      width: fit-content;
      padding: 23px 89px;
      margin: 0px 10px;
      border: 3px solid #fff;
      border-bottom: none;
      font-size: 20px;
      font-weight: bold;
      color: white;
      border-radius: 20px 20px 0px 0px;
      cursor: pointer;
    }

    .Active {
      background-color: $yellow_dark;
      color: #fff;
      border: none;
    }
  }

  .Video_Banner {
    height: auto;
    width: 80%;
    margin: auto;
    border-radius: 60px;

    .slick-prev {
      &::before {
        display: none;
      }

      i {
        color: #05add2;
        font-size: 53px;
        background: white;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: relative;
        left: 17px;
        top: -27px;
        padding: 4px 21px;
        font-weight: 900;

        &:hover {
          background-color: #05add2;
          color: $primary_White;
        }
      }
    }

    .slick-next {
      &::before {
        display: none;
      }

      i {
        color: #05add2;
        font-size: 53px;
        background: white;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: relative;
        right: 46px;
        top: -27px;
        padding: 4px 25px;
        font-weight: 900;

        &:hover {
          background-color: #05add2;
          color: $primary_White;
        }
      }
    }

    .Item {
      width: 100%;
      height: 40vh;
      border-radius: 30px;

      .banner {
        background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/MeekoAssets/banner2.png);
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: 5px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .banner_content {
          h1 {
            font-size: 85px;
            font-weight: 100;
          }

          p {
            color: $primary_White;
            font-size: 65px;
            font-family: "Itim";
            padding: 0px 225px;

            span {
              color: #ffea46;
              // color: $Active_Button_Color;
            }
          }

          img {
            margin: 10px 0px;
          }
        }
      }
    }
  }
}

.About_Banner {
  width: 80%;
  margin: auto;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 65px;

  .Text {
    color: #8484a0;
    font-size: 17px;
    width: 60%;
  }

  .Btn {
    background-color: #52c0d1;
    padding: 15px 20px;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
  }
}

.lendo-page-alignment {
  padding: 80px 0 0 0;

  .cus-container {
    // display: flex;
    width: 75%;
    margin: auto;

    .lendo-grid {
      margin: auto;
    }
  }

  .lendo_title {
    font-size: 35px;
    text-align: center;
    padding-bottom: 30px;
    color: #8484a0;
  }

  .lendo-grid {
    display: grid;
    grid-template-columns: 626px minmax(0, 1fr);
    gap: 0px 60px;

    .lendo-grid-items:nth-child(1) {
      display: flex;
      justify-content: center;
      position: relative;
    }

    .lendo-grid-items {
      position: relative;

      .Join_Btn {
        position: absolute;
        top: 0;
        background-color: #f0c722;
        padding: 12px 20px;
        box-shadow: -31px -43px 36px -47px rgb(0 0 0 / 6%) inset;
        font-size: 20px;
        font-weight: 500;
        width: fit-content;
        margin: auto;
        margin-top: 30px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        z-index: 99;
        // position: relative;
        left: -30px;
        top: 80px;
        transition: 0.5s !important;

        &:hover {
          background-color: #05add2;
        }
      }

      .icon-text-grid {
        display: grid;
        grid-template-columns: 30px minmax(0, 1fr);
        gap: 0 20px;
        align-items: center;
        margin-bottom: 1rem;

        .icon-text-grid-items {
          span {
            font-size: 22px !important;
            @include heading-18;
            color: #8484a0;
            display: block;
            line-height: 1.3;
          }

          .icon-design {
            width: 30px;
            height: 30px;
            background-color: #f0f0f8;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $primary_White;
            position: relative;

            img {
              width: 20px;
              position: absolute;
              right: -5px;
              top: 2px;
            }
          }
        }
      }
    }
  }
}

// slider
.Slider_Container_Main.review_slider_section {
  .slick-next i {
    right: 380px;
  }

  .slick-prev i {
    left: 380px;
  }
}

.Slider_Container_Main {
  margin-top: 50px;
  margin-bottom: 180px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .slick-prev {
    &::before {
      display: none;
    }

    i {
      color: #05add2;
      font-size: 53px;
      background: white;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: relative;
      left: 366px;
      top: 203px;
      padding: 4px 21px;
      font-weight: 900;

      &:hover {
        background-color: #05add2;
        color: $primary_White;
      }
    }
  }

  .slick-next {
    &::before {
      display: none;
    }

    i {
      color: #05add2;
      font-size: 53px;
      background: white;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: relative;
      right: 415px;
      top: 203px;
      padding: 4px 21px;
      font-weight: 900;

      &:hover {
        background-color: #05add2;
        color: $primary_White;
      }
    }
  }

  // .slick-prev,
  // .slick-next {
  //   &::before {
  //     display: none;
  //   }
  //   i {
  //     color: #05add2;
  //     font-size: 53px;
  //     background: white;
  //     width: 65px;
  //     height: 65px;
  //     border-radius: 50%;
  //     position: relative;
  //     left: 0;
  //     top: -27px;
  //     padding: 4px 21px;
  //     font-weight: 900;
  //   }
  // }
  i {
    font-size: 10px;
  }

  .slick-next {
    right: 10px;

    i {
      padding: 4px 25px;
    }
  }

  .Slider_Box_Main {
    .Slider_Heading {
      font-size: 60px;
      font-family: Banda_Niera-Medium;
      text-align: center;
      color: #8484a0;
    }

    .Slider_Box {
      // background-color: #dfe0ec;
      height: 359px;
      width: 977px;
      border-radius: 30px;
      padding: 50px 63px;
      margin-top: -20px;

      .slick-slider .slick-track,
      .slick-slider .slick-list {
        padding: 0px 0px 19px 0px;
      }

      .slick-slide {
        opacity: 0;
        transform: scale(0);
        transition: 2s;
        position: relative;
        height: 359px;
        // padding: 6px 54px 31px 0px;
        padding: 0px;

        &::before {
          width: 90%;
          height: 50px;
          background: white;
          content: "";
          position: absolute;
          z-index: -1;
          bottom: -12px;
          box-shadow: 0px 3px 12px -3px #0000001a;
          left: 5%;
          border-radius: 50px;
        }

        &::after {
          width: 92%;
          height: 50px;
          background: white;
          content: "";
          position: absolute;
          z-index: -1;
          bottom: 5px;
          box-shadow: 0px 3px 12px -3px #0000001a;
          left: 4%;
          border-radius: 50px;
        }
      }

      .slick-slide.slick-active.slick-current {
        opacity: 1;
        transform: scale(1);
        transition: 2s;
        display: flex;
        justify-content: center;
      }

      .slick-dots {
        position: absolute !important;
        bottom: -44px !important;
      }

      .slick-dots li button:before {
        font-size: 15px !important;
        // color: #e5e6ee !important;
      }

      .slick-active button:before {
        color: #c2c4d1 !important;
      }
    }

    .inner_Slider_Box {
      background-color: #e0e1ec;
      border: 3px solid #fff;
      width: 798px !important;
      height: 340px !important;
      border-radius: 30px;
      padding: 45px 67px;

      .Profile_Pic_Box {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;

        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
          border: 3px solid #fff;
        }

        .Title_Container {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-family: Banda_Niera-Medium;
          font-size: 25px;
          color: #8484a0;
        }
      }

      .Comment_Box {
        height: 100%;
        margin-top: 30px;
        font-family: GothamRounded-Medium;
        font-size: 18px;
        color: #8484a0;
      }
    }
  }

  .Slider_main {
    display: flex;
  }

  .Slider_image {
    .left_slider_image {
      position: relative;
      right: 145%;

      .profile1,
      .profile3 {
        transform: scale(0.2);
        -webkit-animation: scal01 3s alternate infinite;
        animation: scal01 3s alternate infinite;
        transition: 1s !important;
      }

      .profile1 {
        top: 13px;
        left: 61px;
      }

      .profile3 {
        left: 42%;
      }

      .profile2 {
        right: 153%;
        transform: scale(0.5);
        -webkit-animation: scal02 2s alternate infinite;
        animation: scal02 2s alternate infinite;
        transition: 1s !important;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        display: block;
        border-radius: 50%;
        opacity: 0.3;
        position: relative;
        top: 58px;
        right: -10%;
        border: 5px solid white;
      }
    }

    .right_slider_image {
      position: relative;
      left: 105%;

      .profile4,
      .profile6 {
        transform: scale(0.7);
        -webkit-animation: scal03 2s alternate infinite;
        animation: scal03 2s alternate infinite;
        transition: 1s !important;
      }

      .profile5 {
        left: 150%;
        transform: scale(0.2);
        -webkit-animation: scal01 3s alternate infinite;
        animation: scal01 3s alternate infinite;
        transition: 1s !important;
      }

      .profile4 {
        top: 0;
      }

      .profile6 {
        right: 77px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        display: block;
        border-radius: 50%;
        opacity: 0.3;
        position: relative;
        top: 58px;
        border: 5px solid white;
      }
    }
  }
}

.landing-footer-alignment {
  background-color: #07abd1;
  padding: 50px 0;
  // position: absolute;

  width: 100%;

  .footer-grid {
    display: flex;
    justify-content: space-between;
    margin-left: 65px;

    .footer-grid-items {
      &:nth-child(1) {
        margin-top: 17px;
      }

      .footer-logo-center {
        display: flex;
        justify-content: center;
        padding: 0 0 1.25rem 0;
      }

      .location-text {
        p {
          @include heading-18;
          color: #fff;
          text-align: center;
          margin: 0 0 8px 0;
        }

        span {
          @include heading-16;
          color: #fff;
          display: block;
          text-align: center;
        }
      }

      .Social_Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        flex-wrap: wrap;
        width: 60%;
        margin-left: 63px;

        img {
          width: 30px;
          height: 30px;
          object-fit: scale-down;
          margin: 5px;
          cursor: pointer;
          transition: 0.5s !important;
        }
      }
    }

    .footer_second_col {
      padding: 28px 0px;

      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        // margin: 0px 192px;
        // padding: 0px 282px 0px 283px;
        margin-bottom: 13px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #90daeb;
          bottom: 0;
        }

        li {
          @include heading-16;
          font-size: 24px !important;
          padding-bottom: 10px;
          padding-left: 16px;
          // padding-right: 11px;
          cursor: pointer;

          a {
            color: #fff;
            text-decoration: none;
            margin: 0 10px;
          }

          &:not(:last-of-type)::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 20px;
            background-color: white;
            top: 5px;
            border-radius: 50px;
            margin: 0px 7px;
          }
        }
      }

      p {
        color: #fff;
        display: flex;
        justify-content: center;
        margin-top: 13px !important;
        text-align: center;
        margin: auto;
        line-height: 23px;
      }
    }
  }
}

.Blue_Back_Container.popular_class_box_section .Common_Card_Container {
  margin-bottom: 10px !important;
}

.Become_Meekonian_Cont.Become_Meekonian_section {
  // padding-top: 105px;
}

// NEW CATEGORY SECTION
.Categories_main_section {
  position: relative;

  img.Round_red {
    position: absolute;
    top: 4%;
    right: 8%;
  }

  .hexa_section {
    display: flex;
    padding: 0;
    width: 1700px;
    margin: auto;

    .hexa_main_row {
      display: flex;

      .hexa_main_col {
        margin: auto;

        .Hexa_main_Cont {
          background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/Categories/hexa_bg_whte_shap.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 373px;
          position: relative;
          // top: -60px;
        }
      }
    }

    .hexa_row {
      margin: auto 0;
      width: 100%;

      .hexa_col {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        img.Paint_Bruch {
          position: absolute;
          top: 0;
          left: 0;
        }

        .Hexa_Cont {
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: center;
          height: 315px;
          width: 275px;
          display: flex;
          transition: 1s;

          .Hexa_content_Cont {
            // padding: 105px 60px;
            margin: auto;

            .hexa_img_block {
              display: flex;
              width: 100%;
              padding-bottom: 10px;
            }

            .hexa_icon {
              margin: auto;
            }

            .cate_name_text {
              text-align: center;
              color: #fff;
              font-size: 18px;
              font-family: GothamRounded-Medium;
              padding: 0px 7px;
            }
          }
        }

        .Hexa_Cont:hover {
          transform: scale(0.9);
          transition: 1s;
        }

        img.Paint_Bruch {
          position: absolute;
          left: 0;
          top: 0;
          width: auto;
          height: 150px;
        }

        img.shoes_boy {
          position: absolute;
          right: 2%;
          top: 99%;
        }

        .book_polygon {
          position: absolute;
          left: 5%;
          bottom: -24%;

          img.Polygon2 {
            position: absolute;
            top: -52%;
            left: 85%;
          }
        }
      }
    }

    .hexa_main_icon {
      height: 100%;
      width: 100%;
      transform: rotate(-90deg);
      object-fit: cover;
    }

    .main_Hexa_content_Cont {
      clip-path: polygon(
        92.32051% 40%,
        93.79385% 43.1596%,
        94.69616% 46.52704%,
        95% 50%,
        94.69616% 53.47296%,
        93.79385% 56.8404%,
        92.32051% 60%,
        79.82051% 81.65064%,
        77.82089% 84.50639%,
        75.35575% 86.97152%,
        72.5% 88.97114%,
        69.3404% 90.44449%,
        65.97296% 91.34679%,
        62.5% 91.65064%,
        37.5% 91.65064%,
        34.02704% 91.34679%,
        30.6596% 90.44449%,
        27.5% 88.97114%,
        24.64425% 86.97152%,
        22.17911% 84.50639%,
        20.17949% 81.65064%,
        7.67949% 60%,
        6.20615% 56.8404%,
        5.30384% 53.47296%,
        5% 50%,
        5.30384% 46.52704%,
        6.20615% 43.1596%,
        7.67949% 40%,
        20.17949% 18.34936%,
        22.17911% 15.49361%,
        24.64425% 13.02848%,
        27.5% 11.02886%,
        30.6596% 9.55551%,
        34.02704% 8.65321%,
        37.5% 8.34936%,
        62.5% 8.34936%,
        65.97296% 8.65321%,
        69.3404% 9.55551%,
        72.5% 11.02886%,
        75.35575% 13.02848%,
        77.82089% 15.49361%,
        79.82051% 18.34936%
      );
      height: 400px;
      width: 400px;
      background-color: #31bbda;
      transform: rotate(90deg);

      .play_Btn {
        .fa.fa-play {
          position: absolute;
          top: 40%;
          right: 42%;
          cursor: pointer;
          width: 75px;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          color: #fe44a5;
          font-size: 12px;
          z-index: 999;
          border: 3px solid #fe44a5;
        }

        .fa.fa-pause {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          width: 75px;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          color: #fe44a5;
          font-size: 32px;
          z-index: 999;
          border: 3px solid #fe44a5;
        }

        i {
          font-size: 30px !important;
          position: absolute !important;
          top: 40% !important;
          left: 41%;
        }

        .fa.fa-play {
          transform: rotate(30deg);
        }

        .fa.fa-pause {
          transform: rotate(90deg);
        }
      }
    }
  }
}

// END CATEGORY SECTION
// Ultra Pro convenient way to use our Platform | NEW SECTION
.Ultra_Pro_section {
  padding: 100px 150px 140px;
  background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/Ultra_Pro/bg_shap.png");
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: -100px;

  // padding: 205px 0;
  .Ultra_Pro_title_row {
    .Top_Cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 25px;
      width: 670px;
      margin: auto;
      text-align: center;

      p {
        color: #8484a0;
        font-size: 60px;
        font-family: Banda_Niera-Medium;
        z-index: 0;
      }

      img.Blue_Wawe {
        width: 203px;
        height: 20px;
        object-fit: contain;
        position: relative;
        top: 3px;
      }
    }
  }

  .Ultra_Pro_content_row {
    display: grid;
    grid-template-columns: 63% 37%;

    // background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/Ultra_Pro/bg_shap.png");
    .Ultra_Pro_col_01 {
      display: grid;
      grid-template-columns: 80% 20%;

      .Ultra_Pro_content_block {
        margin: auto 0;

        .Ultra_Pro_min_font {
          font-size: 38px;
          color: #8484a0;
          font-weight: 600;
        }

        .Ultra_Pro_big_title {
          font-size: 68px;
          color: #06aed3;
          font-weight: 800;
          position: relative;
          margin-bottom: 35px;

          &:after {
            content: "";
            width: 200px;
            height: 5px;
            position: absolute;
            left: 0;
            bottom: -10px;
            background-color: #31bbda;
            border-radius: 100px;
          }
        }

        ul.Ultra_Pro_text {
          li.Ultra_Pro_li {
            font-size: 22px;
            color: #8484a0;
            padding-bottom: 10px;

            &::marker {
              color: #06aed3;
              font-size: 35px;
            }
          }
        }
      }

      .Ultra_Pro_btn_block {
        margin: auto;

        .btn_outter:nth-child(1) {
          margin-bottom: 15px;
        }

        .btn_outter {
          a.btn_inner {
            padding: 6px;
            text-decoration: none;
            border-radius: 50px;
            color: #fff;
            font-size: 23px;
            font-weight: 500;
            text-align: center;
            display: flex;
            width: 215px;
            background-color: rgb(8, 182, 219);
            box-shadow: inset -1.597px 1.204px 8px 0px rgba(0, 0, 0, 0.21);
            border: 3px solid #2bcaed;

            img {
              width: auto;
              height: 55px;
              padding-right: 10px;
            }

            span.btn_inner_text {
              margin: auto 0;
            }
          }
        }
      }
    }

    .Ultra_Pro_col_02 {
      position: relative;

      img.ab_ultra_bell {
        position: absolute;
        top: 10%;
        left: -20%;
      }

      img.ab_ultra_play {
        position: absolute;
        right: 9%;
        top: -4%;
      }

      img.ab_ultra_onlinelaunching {
        position: absolute;
        bottom: 35%;
        right: -3%;
      }
    }
  }
}

// END
//  MEDIA QUERY
@media screen and (max-width: 1399px) {
  .Blue_Back_Container .Card_Container {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .landing-footer-alignment {
    padding: 15px 0;
  }

  .landing-footer-alignment .footer-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .Blue_Back_Container {
    .Common_Card_Container {
      max-width: 350px !important;
      width: 350px;
    }
  }

  .landing-footer-alignment {
    .footer-grid {
      .footer_second_col {
        ul {
          li {
            font-size: 12px !important;
          }
        }
      }

      .footer-grid-items {
        .location-text {
          p {
            font-size: 15px;
          }
        }
      }
    }
  }

  .Landing_Page_Blue_Background .Center_Video_Player {
    .child-image {
      top: -36%;
    }
  }

  .Slider_Container_Main.review_slider_section {
    margin-bottom: 60px;

    .slick-next i {
      right: 255px;
    }

    .slick-prev i {
      left: 315px;
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Bottom_Container .Card_Bottom {
        padding: 8px;
      }

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  .Blue_Back_Container .popular {
    margin-top: 30px !important;
  }

  .Categories_main_section {
    padding-top: 0;
    margin-top: -10px;
  }

  .popular_class_box_section {
    padding-top: 0;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: -60px;
  }

  .Become_Meekonian_Cont .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Categories .Top_Cont p,
  .Ultra_Pro_section .Ultra_Pro_title_row .Top_Cont p {
    font-size: 35px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 10px 50px;
    background-position: 62% 16%;
    background-size: auto 460px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: auto;

        p {
          // font-size: 30px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 100%;
      display: flex;
      flex-wrap: wrap-reverse;

      .Ultra_Pro_col_01 {
        grid-template-columns: 100%;
        display: block;

        .Ultra_Pro_btn_block {
          display: block;
          width: auto;
          padding: 25px 0;

          .btn_outter:nth-child(1) {
            margin-bottom: 10px !important;
          }

          .btn_outter {
            margin: auto !important;

            a.btn_inner {
              padding: 3px;
              width: 165px;
              margin: auto;

              img {
                width: auto;
                height: 40px;
              }

              span.btn_inner_text {
                font-size: 20px;
              }
            }
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 23px;
            padding-bottom: 0px;
          }

          .Ultra_Pro_big_title {
            font-size: 30px;
            margin-bottom: 20px;

            &:after {
              width: 135px;
            }
          }

          ul.Ultra_Pro_text {
            padding-left: 25px;

            li.Ultra_Pro_li {
              font-size: 16px;
              padding-bottom: 0;
              line-height: 1.3;

              &::marker {
                font-size: 20px;
              }
            }
          }
        }
      }

      .Ultra_Pro_col_02 {
        display: flex;
        width: 300px;
        margin: auto;

        .ultra_mobile_cell {
          width: auto;
          height: 340px;
          margin: auto;
        }

        img.ab_ultra_onlinelaunching {
          right: 9%;
          width: auto;
          height: 60px;
          bottom: 22%;
        }

        img.ab_ultra_play {
          right: 4%;
          top: 0%;
          width: auto;
          height: 41px;
        }

        img.ab_ultra_bell {
          left: -1%;
          width: auto;
          height: 50px;
        }
      }
    }
  }

  // END
  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 80px;
  }

  // NEW CATEGORY SECTION
  .Categories.Categories_head_section {
    top: 0;
    padding-top: 0;
  }

  .Categories_main_section {
    img.Round_red {
      top: 2%;
      right: 3%;
      width: auto;
      height: 99px;
    }

    .hexa_section {
      padding: 0 10px;
      width: auto;
      display: block;

      .hexa_row {
        margin: auto;
        width: 305px;

        .hexa_col {
          right: 0;
          position: relative;
          grid-template-columns: 50% 50%;

          img.Paint_Bruch {
            left: -12%;
            top: -50%;
            width: auto;
            height: 84px;
          }

          img.shoes_boy {
            right: 2%;
            top: 99%;
            width: auto;
            height: 57px;
          }

          .book_polygon {
            display: none;
          }

          .Hexa_Cont {
            height: 170px;
            width: auto;
            background-size: auto 165px;

            &:hover {
              transform: scale(1) !important;
              transition: 0s !important;
            }

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 43px;
              }

              .cate_name_text {
                font-size: 15px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 300px;
        width: 300px;
        top: -46px;
        margin: auto;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 285px;
        top: 0;
      }
    }
  }

  // END

  .Blue_Back_Container .popular {
    margin-top: 20px !important;
  }

  .Slider_Container_Main {
    .Slider_Box_Main {
      .Slider_Heading {
        font-size: 35px;
      }

      .Slider_Box {
        width: 717px;
        padding: 0px 50px 84px;
        margin: 12px auto;

        .slick-slide {
          padding: 0px;
        }
      }

      .inner_Slider_Box {
        width: 300px !important;
        height: 316px !important;
        padding: 11px 26px;

        .Comment_Box {
          font-size: 16px;
        }
      }

      .Slider_Box {
        .slick-slide::after {
          width: 275px;
          left: 28%;
          bottom: 32px;
        }

        .slick-slide::before {
          width: 245px;
          left: 30%;
          bottom: 19px;
        }
      }
    }
  }

  .lendo-page-alignment .lendo-grid .lendo-grid-items:nth-child(1) {
    width: 400px;
    margin: auto;

    .Join_Btn {
      left: 24px !important;
      margin-top: -48px !important;
    }
  }

  .Banner_Container .Video_Banner {
    width: 90%;

    .Item {
      height: 25vh;

      .banner {
        border-radius: 20px;
      }
    }
  }

  .Why_Meeko {
    margin-bottom: 60px;

    .why_Meeko_Container {
      margin-top: -80px;
    }

    img.why_meeko {
      width: auto;
      height: 115px;
    }

    .Why11 {
      left: 29%;
    }

    .why_Meeko_Container {
      .Text_Container {
        margin-top: 120px !important;

        .Rode_Container {
          margin-top: 10px;
        }
      }

      .Text_Container_01 {
        position: relative;
        left: 40%;
      }

      .Text_Container_02 {
        right: 39%;
        margin-top: 35px !important;
      }

      .Text_Container_03 {
        left: 42%;
        margin-top: 40px !important;
      }

      .Text_Container_04 {
        right: 46%;
        margin-top: 30px !important;
        width: 20% !important;
      }

      .Text_Container_05 {
        left: 45%;
        position: relative;
        margin-top: 30px !important;
        width: 24% !important;
      }

      .Text_Container_06 {
        right: 44%;
        margin-top: 31px !important;
        width: 26% !important;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why4_Container {
      .Why9 {
        left: 52px;
        width: 67px;
        height: 30px;
      }
    }

    .Top_Cont {
      top: -22px;
      margin-top: 57px;

      img {
        width: auto;
        height: 80px;
      }
    }

    .Join_Btn {
      padding: 12px 13px;
    }

    .white-flow2 {
      width: 268px;
      opacity: 0;
    }

    .Why5 {
      left: -14%;
      height: 65px;
      bottom: 13%;
    }

    .whiteflow1 {
      left: 1%;
      top: 15%;
      width: 185px;
    }

    .whiteflow2 {
      left: 0%;
      top: 70%;
      width: 133px;
      display: none;
    }

    .whiteflow3 {
      right: 0%;
      top: 54%;
      width: 116px;
      left: 50%;
      margin-left: 39px;
      display: none;
    }

    .Why6 {
      right: -27%;
      height: 101px;
      bottom: 23%;
    }

    .Why7 {
      right: 0%;
      height: 138px;
      bottom: -15px;
      left: 38%;
    }

    .Why11 {
      top: 54%;
      left: 8%;
      width: 57px;
      display: none;
    }

    .Why13 {
      right: 4%;
      top: 79%;
      display: none;
    }

    .Why8 {
      position: absolute;
      top: 37%;
      left: 64px;
      width: 35px;
      display: none;
    }

    .Why3_Container {
      right: 0%;
      width: 90px;
      height: 90px;
      top: 42%;

      .Why3 {
        height: 84px;
        margin-right: 9px;
      }

      .Why10 {
        left: -228px;
        width: 27px;
        height: 27px;
        bottom: -7px;
      }
    }

    .Why4_Container {
      width: 90px;
      height: 90px;
      left: 3%;
      top: 54%;

      .Why4 {
        height: 84px;
        top: -2px;
        right: 6px;
      }

      .Why9 {
        left: 27px;
        width: 100%;
        height: 25px;
        object-fit: scale-down;
      }
    }

    .Why1 {
      height: 119px;
      left: 9%;
      top: 19%;
    }

    .Why12 {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 65%;
      left: 9%;
      display: none;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }

    .Why_Meeko .Join_Btn {
      margin-top: 49px;
    }
  }

  // -------
  .Landing_Page_Blue_Background .Center_Video_Player {
    height: 260px !important;
  }

  .About_Meeko_Main_Container {
    margin-top: -45px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .css-1ib9l8o svg {
    display: none !important;
  }

  .Slider_Container_Main {
    .Slider_Box_Main {
      .inner_Slider_Box {
        .Profile_Pic_Box {
          .Title_Container {
            margin-left: 9px;
          }
        }
      }
    }
  }

  .Categories_main_section {
    .hexa_section {
      .main_Hexa_content_Cont {
        .play_Btn {
          .fa.fa-play {
            width: 55px;
            height: 55px;
            font-size: 22px !important;
            top: 42% !important;
          }

          .fa.fa-pause {
            width: 55px;
            height: 55px;
            font-size: 22px !important;
            top: 42% !important;
          }
        }
      }
    }
  }

  .Landing_Page_Background {
    .Responsive_front_Header_Container {
      // padding: 36px 15px 36px 20px;
      padding: 36px 6% 36px 6%;

      .login {
        .css-2b097c-container {
          .css-1392reu-control {
            .css-1ib9l8o {
              .css-1kbzpg9-IndicatorsContainer {
                svg {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .About_Meeko_Main_Container {
    padding-bottom: 83px;
  }

  .Landing_Page_Background {
    .Banner_Container {
      .Video_Banner {
        .slick-slider {
          .slick-arrow {
            display: block !important;
          }

          .slick-prev {
            i {
              width: 40px;
              height: 40px;
              padding: 1px 12px;
              font-size: 35px;
              left: 41px;
              top: -10px;
            }
          }

          .slick-next {
            i {
              font-size: 35px;
              width: 40px;
              height: 40px;
              right: 39px;
              top: -10px;
              padding: 1px 16px;
            }
          }
        }
      }
    }
  }

  .Landing_Page_Blue_Background {
    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: 165px;
          margin: auto;
          width: 99%;

          video {
            object-fit: fill;
          }
        }
      }
    }
  }

  .back_to_top_btn {
    display: none !important;

    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .Landing_Page_Background .Responsive_front_Header_Container .login {
    width: 14%;
  }

  .lendo-page-alignment .lendo_title {
    font-size: 20px;
    padding: 0 10px 20px;
  }

  .Ex_Card_Container
    .Card_Outer_Main_Container
    .Card_Container
    .Right_Side_Container
    .gallery
    .slick-list
    .slick-track
    .slick-slide
    div
    img,
  .Ex_Card_Container
    .Card_Outer_Main_Container
    .Card_Container
    .Right_Side_Container
    .Card_Image_Container
    .Card_Img {
    height: 290px;
  }

  .Blue_Back_Container.popular_class_box_section {
    padding-top: 50px;

    .Card_Container {
      margin-top: 90px !important;
    }
  }

  // img.Blue_Wawe {
  //   width: 151px;
  //   height: 17px;
  //   top: -9px;
  // }
  .Blue_Back_Container {
    .Common_Card_Container {
      max-width: 350px;
      width: 350px;

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    .Landing_Page_Background {
      .Responsive_front_Header_Container {
        padding: 36px 0px 36px 20px;
      }
    }

    .Landing_Page_Blue_Background
      .Center_Video_Player
      .Center_Video_Player_content
      .Card_Image_Container {
      height: 100%;

      video {
        object-fit: cover;
      }
    }

    .Slider_Container_Main {
      .Slider_Box_Main {
        .Slider_Box {
          width: 717px;
          padding: 20px 50px 84px;
          margin: 12px auto;

          .slick-slide {
            padding: 0px;
          }
        }

        .inner_Slider_Box {
          width: 450px !important;
          height: 316px !important;
          padding: 11px 26px;
        }

        .Slider_Box {
          .slick-slide::after {
            width: 421px;
            left: 16%;
            bottom: 32px;
          }

          .slick-slide::before {
            width: 390px;
            left: 18%;
            bottom: 19px;
          }
        }
      }
    }

    .Banner_Container .Video_Banner {
      width: 90%;

      .Item {
        height: 30vh;
      }

      p {
        padding: 0 40px !important;
      }
    }

    .Blue_Back_Container {
      .Common_Card_Container {
        // max-width: 315px;
        .Card_Image_Container {
          height: 170px;
        }
      }
    }

    // start header
    .Landing_Page_Blue_Background {
      .LEFT_SIDE_IMG {
        .Earth {
          left: -24% !important;
        }

        .paint {
          width: 100px !important;
          height: 100px !important;
        }

        .music {
          width: 98px !important;
          height: 98px !important;
        }

        .camera {
          width: 82px !important;
        }
      }
    }

    .Landing_Page_Blue_Background {
      .RIGHT_SIDE_IMG {
        .book {
          width: 56px !important;
        }

        .paint1 {
          width: 82px !important;
        }
      }
    }

    // end header
    //start why meeko
    .Why_Meeko {
      .Why2 {
        width: 59px !important;
      }

      .Why3_Container {
        width: 100px !important;
        height: 100px !important;

        .Why3 {
          height: 100px !important;
        }

        .Why10 {
          left: -257px !important;
        }
      }

      // .Why4_Container {
      //     width: 100px !important;
      //     height: 100px !important;
      //     .Why4 {
      //         height: 102px !important;
      //     }
      // }
      // .Why7 {
      //     height: 138px !important;
      //     bottom: 96px !important;
      //     left: 58% !important;
      // }
      // .Why6 {
      //     right: -5% !important;
      //     height: 119px !important;
      //     bottom: 34% !important;
      // }
      // .Why5 {
      //     left: -6% !important;
      //     height: 86px !important;
      //     bottom: 24% !important;
      // }
    }

    //end why meeko
    // .Categories_media {
    //   display: block !important;
    // }
    // .Categories {
    //   display: none;
    // }
  }

  .css-26l3qy-menu {
    position: fixed !important;
    width: 50% !important;
    top: 67px !important;
    left: auto;
    right: 6px;
  }

  .Login2 {
    display: block !important;
  }

  .Login1 {
    display: none !important;
  }

  .css-1392reu-control {
    background-color: transparent !important;
  }

  .css-k7p11r-indicatorContainer {
    display: block !important;
    position: absolute !important;
    top: 10px !important;
    left: -4px !important;
  }

  .css-3xnrlj-control {
    //  display: none !important;
    background-color: transparent !important;
  }

  .css-2b097c-container {
    // width: 55px   !important;
  }

  .css-1kbzpg9-IndicatorsContainer svg {
    border-top: 9px solid white !important;

    display: none;
  }

  .Responsive_front_Header_Container .login img {
    position: relative;
    bottom: 37px;
    left: 5px;
    width: 19px;
    z-index: -1;
  }

  .css-vps8j1-indicatorContainer {
    display: block !important;
    position: absolute !important;
    top: 7px !important;
    left: -4px !important;
  }

  // =======================  Banner
  .Logo_Responsive {
    width: 104px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    left: 36%;
    width: 78px;
    height: 78px;
    top: 49%;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 77px;
    height: 77px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -36%;
    width: 110px;
    height: 110px;
    display: none;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 100px;
    height: 100px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image {
    // top: -46.5%;
  }

  .Card_Image_Container {
    // height: 315px !important;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image::before {
    background-size: 72px 80px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image-right::after {
    background-size: 57px 84px;
    top: 74%;
  }

  // ================
  .Blue_Back_Container .Top_Cont .Blue_Wawe {
    margin: 12px 0px;
    width: 141px;
  }

  //start HEADER
  .Landing_Page_Blue_Background .Center_Video_Player {
    &::after {
      width: 69px;
      height: 30px;
      right: -11%;
      display: none;
    }

    &::before {
      width: 69px;
      height: 30px;
    }

    video.Card_Img {
      // height: 251px;
      // object-fit: contain;
    }

    .child-image {
      left: 50%;
      height: 93px;
      width: 184px;
      background-size: 184px;
    }
  }

  .nav-menu-educator {
    width: 319px;
  }

  .Center_Video_Player_content .Card_Image_Container .play_Btn {
    i {
      font-size: 16px !important;
      width: 50px !important;
      height: 50px !important;
    }
  }

  .login .css-2b097c-container {
    width: 0px;
    height: 100%;
  }

  .css-1kbzpg9-IndicatorsContainer {
    display: none;
  }

  .css-1kbzpg9-IndicatorsContainer {
    width: 64px;
    display: none !important;
  }

  // .css-1kbzpg9-IndicatorsContainer{
  //   padding-right: 45px !important;
  // }
  .css-k7p11r-indicatorContainer {
    padding-left: 35px !important;
  }

  .css-vps8j1-indicatorContainer {
    padding-left: 35px !important;
  }

  .css-1392reu-control {
    padding-left: 0px !important;
    // background-color: #680a5f !important;
  }

  .css-3xnrlj-control {
    padding-left: 0px !important;

    // background-color: #680a5f !important;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 412px !important;
    height: 446px !important;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 126px;
      height: 51px;
    }

    li a {
      font-size: 14px;
      margin-right: 13px;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 14px;
      margin-left: 11px;
    }

    .menu {
      margin-left: 209px;
    }

    .logo {
      left: -163px;
      width: 350px;
      height: 364px;
      background: none;

      img {
        right: 16%;
        bottom: 19%;
        width: 123px;
      }
    }
  }

  .Landing_Page_Blue_Background {
    top: 68px;
    background-color: $primaryblue !important;

    .Center_Video_Player {
      width: 100%;
      height: 325px;
      bottom: 50px;
    }

    .Center_Video_Player::before {
      left: -6%;
      display: none;
    }

    .RIGHT_SIDE_IMG .Right_Box {
      width: 190px;
      top: 41%;
      right: 2%;
      height: 145px;
    }

    .LEFT_SIDE_IMG {
      top: -169px;

      img {
        width: 53px;
      }

      .Star3 {
        top: 34%;
        left: 47%;
      }

      .music {
        left: 34%;
        top: 49%;
        display: none;
      }

      .Star1 {
        left: 28%;
        width: 25px;
        bottom: -128px;
      }

      .Star2 {
        position: absolute;
        top: 53%;
        left: 25%;
        width: 20px;
      }

      .paint {
        top: 34%;
        width: 80px;
        left: -11px;
        display: none;
      }

      .camera {
        bottom: 20%;
        left: 9%;
        width: 70px;
        display: none;
      }
    }

    .RIGHT_SIDE_IMG {
      top: -51px;

      .Right_Box {
        .Container {
          .Offer {
            width: 155px;
            margin-top: 7px;
          }
        }
      }

      .paint1 {
        right: 10%;
        top: 61%;
        width: 57px;
        height: auto;
        display: none;
      }

      .book {
        height: auto;
        top: 56%;
        right: 70%;
        width: 37px;
        display: none;
      }

      .Star4 {
        top: 15%;

        right: 32%;
      }

      .Star6 {
        right: 30%;
      }

      .Rocket {
        bottom: -5px;
        width: 134px;
        right: 4%;
        display: none;
      }
    }
  }

  // end HEADER
  // start WHAT IS MEEKO
  .About_Meeko_Main_Container .Illustration_media {
    display: none;
  }

  .About_Meeko_Main_Container .Center_Text .Orange_About_Box {
    margin-top: 50px;
    width: 90%;
    margin-left: 18px;

    .Title {
      font-size: 25px;
    }
  }

  .About_Meeko_Main_Container .Center_Text .Orange_About_Box {
    margin-top: 10px;
    width: 90%;
    margin-left: 18px;
  }

  .About_Meeko_Main_Container .Center_Text .Top_About {
    .Small_Had {
      font-size: 26px;
    }

    .Big_Had {
      font-size: 45px;
    }
  }

  .About_Meeko_Main_Container .Center_Text .Pink_Box {
    margin-top: 20px;
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 88px 0px 0px 0px;

      img {
        width: 142px;
      }
    }

    .Rocket1 {
      bottom: -75px;
      right: 5%;
      width: 69px;
    }

    .Center_Text .Blue_Box {
      // margin-left: 0 !important;
      text-align: right;
      // margin-top: 41px;
      // margin-left: auto;
      margin: 20px auto auto auto;
    }

    .Center_Text .Orange_About_Box .Details {
      font-size: 15px;
      font-weight: 400;
      max-width: 600px;
      color: #8484a0;
      margin: 0 0 1.25rem 0;
    }

    .Big_Round_Left {
      width: 388px;
      height: 388px;
      top: -7%;
      left: -19%;
      display: none;
    }

    .Illustration1 {
      top: 298px;
      height: 152px;
      left: -75px;
    }

    .Big_Round_Bottom_Left {
      bottom: -25%;
      width: 316px;
      height: 316px;
      left: -138px;
      left: -138px;
      display: none;

      .Illustration2 {
        height: 224px;
        right: -49%;
        top: 60px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 15%;
      right: 0;
      width: 135px;
      height: 288px;
      display: none;

      .Illustration3 {
        left: -61%;
        height: 185px;
        bottom: -8%;
        width: auto;
      }
    }

    .child1 {
      top: 18%;
      right: 27%;
      width: 29px;
      display: none;
    }

    .Earth_Round_Bottom_Right {
      // display: none;
      .earthBack {
        position: relative;
        bottom: -101px;
        width: 73px;
        display: none;
      }

      .earth1 {
        right: 0;
        top: 149px;
        width: 55px;
        left: 40px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 70%;
      width: 92px;
      display: none;
    }
  }

  // end WHAT IS MEEKO
  //start Try Demo Class for
  .Blue_Back_Container .Top_Cont .Free {
    position: absolute;
    top: 65px;
    width: 100px;
  }

  // end Try Demo Class for
  // categories start
  .Categories {
    top: -83px;

    .Top_Cont {
      top: 0;
    }

    .Hexagonal_Container {
      .Hexa_Cont:nth-child(5) {
        margin-left: 4% !important;
      }

      .Hexagonal_media {
        display: block;
        width: 263px;
      }

      .Top_Hexagonal_Container {
        margin-bottom: 60px;
        display: block;
        width: auto;

        .Hexagonal_for_media {
          display: none;
        }

        .Hexagonal {
          width: 134px;
        }

        .Hexa_Cont {
          margin: 20px 10px 0px 10px;

          .Hexa_content_Cont {
            margin: 0;
            transform: translate(-48%, -75%);

            p {
              display: none;
            }

            .hexa {
              width: 66px;
              height: 66px;
              object-fit: contain;
            }
          }

          .hexa_content {
            display: block;
            text-align: center;
            color: #8484a0;
            width: 129px;
          }
        }
      }
    }

    .Top_Cont p {
      color: #8484a0;
      font-size: 34px;
      font-family: Banda_Niera-Medium;
    }

    .shoes_boy {
      width: 100px;
      bottom: -46px;
    }

    .Paint_Bruch {
      bottom: 18%;
      left: 28%;
      width: 95px;
    }

    .Round_red {
      position: absolute;
      top: -14%;
      right: 5%;
      width: 88px;
    }

    .book_polygon {
      right: 20px;
      bottom: 218px;
      display: none;

      .Polygon1 {
        width: 113px;
        top: -34px;
        left: 50px;
      }

      .book {
        left: 77px;
        top: 2px;
        width: 66px;
      }

      .Polygon2 {
        bottom: 151px;
        left: 10px;
        width: 83px;
      }
    }
  }

  // .Categories .Hexagonal_Container .Bottom_Hexagonal_Container {
  //   display: block;
  //   margin-left: 2%;
  //   margin-top: -69px;
  //   .Hexagonal {
  //     width: 134px;
  //   }
  //   .Hexa_Cont {
  //     margin: 20px 10px 0px 10px;
  //     .Hexa_content_Cont {
  //       margin: 0;
  //       transform: translate(-48%, -84%);
  //       p {
  //         display: none;
  //       }
  //       .hexa {
  //         width: 55px;
  //         height: 55px;
  //         object-fit: contain;
  //       }
  //     }
  //     .hexa_content {
  //       display: block;
  //       text-align: center;
  //       color: #8484a0;
  //     }
  //     .hexa_content.hexa_content_edit {
  //       width: 129px;
  //     }
  //   }
  // }
  // categories end
  .Blue_Back_Container {
    .Card_Container {
      margin-top: -53px;
      padding: 7px 4px;
      margin-top: 10px !important;
    }

    .Top_Cont {
      p {
        text-align: center;
        font-size: 34px;
      }

      .white-flow {
        position: relative;
        top: -57px;
        width: 164px;
      }
    }
  }

  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    // display: block;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: auto;
    z-index: 0;

    .Next_arrow img {
      top: 28%;
      left: 34%;
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .Become_Meekonian_Cont .Top_Cont {
    top: -86px;

    p {
      font-size: 34px;
      text-align: center;
      padding-top: 125px;
    }
  }

  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 60px;
      height: 50px;
      margin: 0 auto;
      margin-bottom: 10px;
      top: 0;
      display: none;
    }
  }

  .Blue_Back_Container .Top_Cont {
    top: 0;
    margin-top: -55px;
    margin-bottom: -75px;
  }

  .Blue_Back_Container {
    height: 745px;
    margin-top: 0px;
    padding-top: 0;
  }

  .Blue_Back_Container .popular_cont {
    // margin-top: 33px;
    top: 15px;
  }

  .Become_Meekonian_Cont .Become_Cont {
    margin-top: -97px;
    padding: 18px 0px;

    .Round_Blue_Left {
      display: none;
    }

    .Round_Blue_Right {
      display: none;
    }
  }

  .Become_Meekonian_Cont
    .Become_Cont
    .Content_Container_Main
    .Sub_Content_Container {
    margin: 10px 0;
    display: -webkit-box;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    .Round_Cont {
      width: 80px;
      height: 80px;
      margin-left: 10px;
    }

    .Round_Cont img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 16px;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-right: 100px;
      width: 100%;
      padding-left: 12px;
      text-align: left;
      align-items: center;
      display: flex;
    }
  }

  // end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 30%;
    margin-top: 50px;

    // margin: 0 auto;
    p {
      font-size: 20px;
    }
  }

  // .Why_Meeko .why_Meeko_Container {
  //     .Blue_Cont {
  //         margin: 56px auto;
  //     }
  //     .Pink_Cont {
  //         margin: 56px auto;
  //     }
  //     .Orange_Cont {
  //         margin: 56px auto;
  //     }
  //     .Yellow_Cont {
  //         margin: 56px auto;
  //     }
  //     .BluePink_Cont {
  //         margin: 56px auto;
  //     }
  // }
  .Why_Meeko .Why2 {
    top: 143px;
    left: 79%;
    width: 49px;
    display: none;
  }

  // end why meeko
  // start LEADO
  .lendo-page-alignment {
    padding: 40px 0px 0;

    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;
      grid-template-columns: 300px minmax(0, 1fr);

      .lendo-grid-items {
        .icon-text-grid {
          margin-bottom: 0.5rem;

          .icon-text-grid-items span {
            font-size: 15px !important;
          }
        }
      }

      img.LendoImage {
        width: auto;
        height: 180px;
        margin: auto 20px auto auto;
      }
    }
  }

  // -----
  .lendo-page-alignment .cus-container {
    padding: 0 !important;
  }

  .lendo-page-alignment
    .lendo-grid
    .lendo-grid-items
    .icon-text-grid
    .icon-text-grid-items
    span {
    font-size: 17px !important;
  }

  .lendo-page-alignment .lendo-grid {
    display: block;

    .lendo-grid-items {
      position: relative;
      margin: 0px 5px;
      width: 85%;

      .Join_Btn {
        left: 54px;
        margin-top: -40px;
        font-size: 13px;
        padding: 7px 11px;
      }

      .LendoImage {
        width: 265px;
        margin: auto;
      }
    }
  }

  // end LEADO
  // start Banner
  .Banner_Container {
    margin-top: 0px;
    width: 100%;

    .Banner_Btn_Group div {
      padding: 14px 23px;
      margin: 0px 3px;
      font-size: 12px;
    }

    .Video_Banner {
      width: 94%;

      .Item .banner {
        .banner_content {
          // left: 8%;
          h1 {
            font-size: 20px;
          }

          p {
            font-size: 25px;
            padding: 0 26px;
          }
        }
      }
    }
  }

  .About_Banner .Text {
    height: 87px;
    overflow: auto;
  }

  .About_Banner {
    padding: 13px 12px;

    .Btn {
      padding: 15px 8px;
      border-radius: 10px;
      font-size: 13px;
    }
  }

  // end Banner
  //start SLIDER
  .Slider_Container_Main {
    margin-bottom: 90px;

    .slick-prev i,
    .slick-next i {
      width: 50px;
      height: 50px;
      font-size: 40px;
      padding: 4px 18px;
    }

    .Slider_main {
      display: block;
    }

    .slick-prev i {
      left: 305px;
      top: 170px;
    }

    .slick-next i {
      right: 250px;
      top: 170px;
    }

    .slick-prev {
      display: flex !important;
      justify-content: center;
    }

    .slick-next {
      display: flex !important;
      justify-content: center;
    }

    .Slider_image {
      .right_slider_image {
        left: 186px;
        display: flex;

        img {
          width: 70px;
          height: 70px;
          display: none !important;
        }

        .profile6 {
          right: 0;
          left: 106px;
          top: 10px;
        }

        .profile5 {
          left: 0;
        }

        .profile4 {
          right: 0;
        }
      }

      .left_slider_image {
        display: flex;
        left: 268px;

        img {
          width: 70px;
          height: 70px;
          right: 0;
          display: none !important;
        }

        .profile1 {
          left: -53px;
          top: 75px;
        }

        .profile2 {
          right: 0;
        }

        .profile3 {
          left: 22px;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        // right: -63%;
        // top: 68px;
        .profile2 {
          // right: -17%;
          // top: 23px;
        }
      }

      .right_slider_image {
        //   left: -48%;
        // top: 93px;
        .profile5 {
          // left: -21%;
        }
      }
    }
  }

  // end SLIDER
  // start FOOTER
  .landing-footer-alignment {
    padding: 20px 0;

    .cus-container {
      padding: 0 0px !important;
    }

    .footer-grid {
      font-size: 15px;
      margin-left: 0;
      display: block;
      text-align: center;

      .footer-grid-items {
        .Social_Icon {
          width: 100%;
          margin: 0 auto;
        }
      }

      .footer_second_col {
        padding: 15px 0px;

        p {
          padding: 0px 4px;
          font-size: 12px;
        }

        ul {
          display: block;

          &::after {
            width: 100%;
            bottom: -5px;
            left: 0;
          }

          li {
            font-size: 14px !important;
            padding-left: 10px;
            padding-right: 9px;
            padding-bottom: 0px;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  // end FOOTER
  .Landing_Page_Blue_Background {
    // height: 400px;
    height: 375px;
    padding-top: 167px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .Blue_Back_Container .Common_Card_Container {
    max-width: 290px;
    width: 290px;
  }

  .Card_Image_Container {
    // height: 250px !important;
  }

  .Center_Video_Player_content {
    .Card_Image_Container {
      .play_Btn {
        right: 40%;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .Landing_Page_Blue_Background {
    height: 100vw;
    padding-top: 167px;
  }

  .landing-footer-alignment .footer-grid .footer_second_col ul {
    display: block;

    &::after {
    }

    li {
      font-size: 16px !important;
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  .Blue_Back_Container.try_demo_class_card_section,
  .Blue_Back_Container.popular_class_box_section {
    .Common_Card_Container {
      max-width: 290px;
    }
  }

  .Ex_Card_Container
    .Card_Outer_Main_Container
    .Card_Container
    .Right_Side_Container
    .gallery
    .slick-list
    .slick-track
    .slick-slide
    div
    img,
  .Ex_Card_Container
    .Card_Outer_Main_Container
    .Card_Container
    .Right_Side_Container
    .Card_Image_Container
    .Card_Img {
    height: 320px;
  }

  .Landing_Page_Blue_Background {
    height: 100vw;

    .Center_Video_Player {
      .child-image {
        top: -29%;
      }
    }
  }

  .Slider_Container_Main {
    .Slider_Box_Main {
      .inner_Slider_Box {
        .Comment_Box {
          font-size: 16px;
        }
      }

      .Slider_Heading {
        font-size: 45px;
      }
    }
  }

  .Slider_Container_Main.review_slider_section {
    margin-bottom: 100px;

    .slick-next i {
      right: 255px;
    }

    .slick-prev i {
      left: 315px;
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Bottom_Container .Card_Bottom {
        padding: 8px;
      }

      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  .Blue_Back_Container .popular {
    margin-top: 30px !important;
  }

  .Categories_main_section {
    padding-top: 0;
    margin-top: -25px;
  }

  .popular_class_box_section {
    padding-top: 0;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: -60px;
  }

  .Become_Meekonian_Cont .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Categories .Top_Cont p,
  .Ultra_Pro_section .Ultra_Pro_title_row .Top_Cont p {
    font-size: 40px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 10px 80px;
    background-position: 62% 16%;
    background-size: auto 683px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 400px;

        p {
          // font-size: 35px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 100%;
      display: flex;
      flex-wrap: wrap-reverse;

      .Ultra_Pro_col_01 {
        grid-template-columns: 100%;
        display: block;

        .Ultra_Pro_btn_block {
          display: flex;
          width: 360px;
          padding: 25px 0;

          .btn_outter {
            margin: auto !important;

            a.btn_inner {
              padding: 3px;
              width: 165px;

              img {
                width: auto;
                height: 40px;
              }

              span.btn_inner_text {
                font-size: 20px;
              }
            }
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 25px;
            padding-bottom: 0px;
          }

          .Ultra_Pro_big_title {
            font-size: 35px;
            margin-bottom: 20px;

            &:after {
              width: 135px;
            }
          }

          ul.Ultra_Pro_text {
            padding-left: 40px;

            li.Ultra_Pro_li {
              font-size: 17px;
              padding-bottom: 0;
              line-height: 0.8;
            }
          }
        }
      }

      .Ultra_Pro_col_02 {
        display: flex;
        width: 430px;
        margin: auto;

        .ultra_mobile_cell {
          width: auto;
          height: 460px;
          margin: auto;
        }

        img.ab_ultra_onlinelaunching {
          right: 5%;
          width: auto;
          height: 57px;
        }

        img.ab_ultra_play {
          right: 11%;
          top: 0%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: 3%;
          width: auto;
          height: 55px;
        }
      }
    }
  }

  // END
  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 90px;
  }

  // NEW CATEGORY SECTION
  .Categories.Categories_head_section {
    top: 0;
    padding-top: 0;
  }

  .Categories_main_section {
    img.Round_red {
      top: -2%;
      right: 6%;
      width: auto;
      height: 120px;
    }

    .hexa_section {
      padding: 0 10px;
      width: auto;
      display: block;

      .hexa_row {
        margin: auto;
        width: 430px;

        .hexa_col {
          right: 0;
          position: relative;
          grid-template-columns: 50% 50%;

          img.Paint_Bruch {
            left: -9%;
            top: -11%;
            width: auto;
            height: 102px;
          }

          img.shoes_boy {
            width: auto;
            height: 77px;
          }

          .book_polygon {
            display: none;
          }

          .Hexa_Cont {
            height: 210px;
            width: auto;
            background-size: auto 205px;

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 50px;
              }

              .cate_name_text {
                font-size: 15px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 300px;
        width: 300px;
        top: -46px;
        margin: auto;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 285px;
        top: 0;
      }
    }
  }

  .Card_Image_Container {
    // height: 315px !important;
  }

  // END
  .lendo-page-alignment .lendo-grid .lendo-grid-items:nth-child(1) {
    width: 470px;
    margin: auto;
  }

  .Banner_Container .Video_Banner .Item .banner .banner_content {
    left: 9.5%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player video.Card_Img {
    // height: 314px;
  }

  .About_Meeko_Main_Container {
    margin-top: 26px;
  }

  .Why_Meeko {
    margin-bottom: 60px;

    .why_Meeko_Container {
      margin-top: -80px;
    }

    img.why_meeko {
      width: auto;
      height: 115px;
    }

    .Why11 {
      left: 29%;
    }

    .why_Meeko_Container {
      .Text_Container .Rode_Container {
        margin-top: 10px;
      }

      .Text_Container_01 {
        position: relative;
        left: 40%;
      }

      .Text_Container_02 {
        right: 33%;
        margin-top: 35px;
      }

      .Text_Container_03 {
        left: 35%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 35%;
      }

      .Text_Container_05 {
        left: 34%;
        position: relative;
      }

      .Text_Container_06 {
        right: 35%;
        margin-top: 55px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why4_Container {
      .Why9 {
        left: 52px;
        width: 67px;
        height: 30px;
      }
    }

    .Top_Cont {
      top: -22px;
      margin-top: 57px;

      img {
        width: auto;
        height: 100px;
      }
    }

    .Join_Btn {
      padding: 12px 13px;
    }

    .white-flow2 {
      width: 268px;
      opacity: 0;
    }

    .Why5 {
      left: -14%;
      height: 65px;
      bottom: 15%;
    }

    .whiteflow1 {
      left: 1%;
      top: 16%;
      width: 185px;
    }

    .whiteflow2 {
      left: 0%;
      top: 70%;
      width: 133px;
      display: none;
    }

    .whiteflow3 {
      top: 33%;
      right: 4%;
      width: 116px;
      left: 50%;
      margin-left: 39px;
      display: none;
    }

    .Why6 {
      right: -19%;
      height: 101px;
      bottom: 26%;
    }

    .Why7 {
      right: 0%;
      height: 138px;
      bottom: -15px;
      left: 56%;
    }

    .Why11 {
      top: 54%;
      left: 8%;
      width: 57px;
      display: none;
    }

    .Why13 {
      right: 4%;
      top: 79%;
    }

    .Why8 {
      position: absolute;
      top: 37%;
      left: 64px;
      width: 35px;
    }

    .Why3_Container {
      right: 3%;
      width: 90px;
      height: 90px;
      top: 35%;

      .Why3 {
        height: 84px;
        margin-right: 9px;
      }

      .Why10 {
        left: -228px;
        width: 27px;
        height: 27px;
        bottom: -7px;
      }
    }

    .Why4_Container {
      width: 110px;
      height: 110px;
      left: 4%;
      top: 49%;

      .Why4 {
        height: 116px;
        top: -6px;
        right: 8px;
      }

      .Why9 {
        left: 27px;
        width: 100%;
        height: 25px;
        object-fit: scale-down;
      }
    }

    .Why1 {
      height: 119px;
      left: 5%;
      top: 21%;
    }

    .Why12 {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 65%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }

    .Why_Meeko .Join_Btn {
      margin-top: 49px;
    }
  }

  // -------
}

@media screen and (min-width: 850px) and (max-width: 930px) {
  .About_Meeko_Main_Container .Illustration1 {
    right: -34% !important;
  }

  .About_Meeko_Main_Container .Big_Round_Bottom_Right .Illustration3 {
    left: -90% !important;
  }

  .About_Meeko_Main_Container .Big_Round_Bottom_Left .Illustration2 {
    right: -60% !important;
  }
}

@media screen and (min-width: 931px) and (max-width: 980px) {
  .About_Meeko_Main_Container .Illustration1 {
    right: -44% !important;
  }

  .About_Meeko_Main_Container .Big_Round_Bottom_Right .Illustration3 {
    left: -120% !important;
  }

  .About_Meeko_Main_Container .Big_Round_Bottom_Left .Illustration2 {
    right: -70% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  .Landing_Page_Background {
    @keyframes fly {
      0% {
        transform: translateY(70vh);
      }

      100% {
        transform: translateY(-160vh);
      }
    }

    .Banner_Container {
      .Video_Banner {
        .slick-slider {
          .slick-arrow {
            display: block !important;
          }

          .slick-prev {
            i {
              width: 50px;
              height: 50px;
              padding: 0px 15px;
              font-size: 45px;
              left: 29px;
              top: -17px;
            }
          }

          .slick-next {
            i {
              font-size: 45px;
              width: 50px;
              height: 50px;
              right: 39px;
              top: -17px;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }

  .Landing_Page_Blue_Background {
    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: 258px;
        }
      }
    }
  }

  .back_to_top_btn {
    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .Become_Meekonian_Cont {
    .Become_Cont {
      .Content_Container_Main {
        .Next_arrow {
          img {
            width: 25px;
            height: 25px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .css-1392reu-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }

  .lendo-page-alignment .lendo_title {
    font-size: 22px;
  }

  .Slider_Container_Main.review_slider_section {
    margin-bottom: 130px;

    .slick-next i {
      right: 285px;
    }

    .slick-prev i {
      left: 350px;
    }
  }

  .About_Meeko_Main_Container {
    margin-top: -100px;
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Bottom_Container .Card_Bottom {
        padding: 8px;
      }

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  .Blue_Back_Container .popular {
    margin-top: 30px !important;
  }

  .Categories_main_section,
  .Categories.Categories_head_section,
  .popular_class_box_section {
    padding-top: 0;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: 120px;
  }

  .Become_Meekonian_Cont .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Categories .Top_Cont p,
  .Ultra_Pro_section .Ultra_Pro_title_row .Top_Cont p {
    font-size: 45px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 30px 10px 80px;
    background-position: 42% 1%;
    background-size: auto 760px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 480px;

        p {
          // font-size: 35px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 100%;
      display: flex;
      flex-wrap: wrap-reverse;

      .Ultra_Pro_col_01 {
        grid-template-columns: 67% 33%;

        .Ultra_Pro_btn_block {
          display: block;
          width: 360px;
          padding: 25px 0;

          .btn_outter:nth-child(1) {
            margin-bottom: 15px !important;
          }

          .btn_outter {
            margin: auto !important;

            a.btn_inner {
              padding: 3px;
              width: 165px;

              img {
                width: auto;
                height: 40px;
              }

              span.btn_inner_text {
                font-size: 20px;
              }
            }
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 25px;
            padding-bottom: 0px;
          }

          .Ultra_Pro_big_title {
            font-size: 35px;
            margin-bottom: 20px;

            &:after {
              width: 135px;
            }
          }

          ul.Ultra_Pro_text {
            padding-left: 40px;

            li.Ultra_Pro_li {
              font-size: 17px;
              padding-bottom: 0;
              line-height: 0.8;
            }
          }
        }
      }

      .Ultra_Pro_col_02 {
        display: flex;
        width: 480px;
        margin: auto;

        .ultra_mobile_cell {
          width: auto;
          height: 460px;
          margin: auto;
        }

        img.ab_ultra_onlinelaunching {
          right: -6%;
          width: auto;
          height: 57px;
        }

        img.ab_ultra_play {
          right: 11%;
          top: 0%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: 3%;
          width: auto;
          height: 55px;
        }
      }
    }
  }

  // END
  // NEW CATEGORY SECTION
  .Blue_Back_Container.popular_class_box_section {
    padding-top: 40px;
  }

  .Categories_main_section {
    img.Round_red {
      top: 32%;
      right: 7%;
      width: auto;
      height: 135px;
    }

    .hexa_section {
      padding: 0 10px;
      width: auto;
      display: block;

      .hexa_row {
        margin: auto;
        width: 760px;

        .hexa_col {
          // right: -90px;
          position: relative;
          grid-template-columns: 25% 25% 25% 25%;

          img.Paint_Bruch {
            top: -58%;
          }

          img.shoes_boy {
            width: auto;
            height: 110px;
          }

          .book_polygon {
            display: none;
          }

          .Hexa_Cont {
            height: 190px;
            width: 195px;
            background-size: auto 180px;

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 50px;
              }

              .cate_name_text {
                font-size: 15px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 300px;
        width: 300px;
        top: -46px;
        margin: auto;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 285px;
        top: 0;
      }
    }
  }

  // END
  .Banner_Container .Video_Banner {
    width: 90%;

    .Item {
      height: 35vh;
    }
  }

  .Why_Meeko {
    margin-bottom: 100px;

    .why_Meeko_Container {
      margin-top: -80px;
    }

    img.why_meeko {
      width: auto;
      height: 115px;
    }

    .Why11 {
      left: 29%;
    }

    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 33%;
      }

      .Text_Container_02 {
        right: 30%;
        margin-top: 35px;
      }

      .Text_Container_03 {
        left: 30%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 35%;
      }

      .Text_Container_05 {
        left: 30%;
        position: relative;
      }

      .Text_Container_06 {
        right: 35%;
        margin-top: 55px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why4_Container {
      .Why9 {
        left: 135px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont {
    margin-top: -40px;

    .Round_Blue_Left {
      width: 260px;
      height: 270px;
      bottom: -13%;
    }

    .Round_Blue_Right {
      width: 150px;
      height: 310px;
    }

    .Become_Cont {
      padding: 20px 20px;
    }

    .Top_Cont {
      padding-bottom: 80px;
    }
  }

  .Categories {
    padding-top: 0;
  }

  .About_Meeko_Main_Container .Center_Text .Orange_About_Box .Rode_Container {
    .Big_Rodem {
      height: 6px;
    }

    .Small_Rode {
      height: 9px;
    }
  }

  .About_Meeko_Main_Container .Center_Text .Orange_About_Box {
    width: 55%;
    margin: auto;
  }

  .Blue_Back_Container {
    .Common_Card_Container {
      max-width: 350px;
      width: 350px;

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    left: 36%;
    width: 100px;
    height: 100px;
    top: 49%;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 111px;
    height: 111px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -19%;
    width: 161px;
    height: 161px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 100px;
    height: 100px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
    bottom: 5%;
    right: -14%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image {
    top: -49.5%;
  }

  .Card_Image_Container {
    // height: 340px !important;
  }

  // ========== WHY IS MEEKo =================================
  img.Blue_Wawe {
    width: 155px;
  }

  //start HEADER
  .css-1392reu-control {
    height: 45px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player video.Card_Img {
    // object-fit: contain;
    // height: 340px;
  }

  .Center_Video_Player_content .Card_Image_Container .play_Btn {
    i {
      font-size: 18px !important;
      width: 50px !important;
      height: 50px !important;
    }
  }

  .css-k7p11r-indicatorContainer {
    padding-left: 72px !important;
  }

  .css-vps8j1-indicatorContainer {
    padding-left: 72px !important;
  }

  .css-1392reu-control {
    padding-left: 5px !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 126px;
      height: 51px;
    }

    li a {
      font-size: 14px;
      margin-right: 13px;
    }
  }

  .Landing_Page_Blue_Background .Center_Video_Player .Center_Video iframe {
    width: 412px;
    height: 447px;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 412px !important;
    height: 446px !important;
    background-repeat: no-repeat;
    background-size: contain !important;
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 14px;
      margin-left: 11px;
    }

    .menu {
      margin-left: 209px;
    }

    .logo {
      left: -163px;
      width: 383px;
      height: 383px;

      img {
        right: 17%;
        bottom: 19%;
        width: 144px;
      }
    }
  }

  .Landing_Page_Blue_Background {
    top: 28px;
    height: 814px;

    .Center_Video_Player::before {
      left: -4%;
      right: -22%;
    }

    .Center_Video_Player {
      width: 75%;
      bottom: -15%;
      height: 350px;
    }

    .RIGHT_SIDE_IMG .Right_Box {
      width: 236px;
      top: 37%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -229px;

      img {
        width: 115px;
      }

      .Star1 {
        left: 28%;
        width: 25px;
      }

      .Star2 {
        position: absolute;
        top: 46%;
        left: 25%;
        width: 20px;
      }

      .paint {
        top: 40%;
        left: 66%;
      }

      .camera {
        bottom: 2%;
        left: 9%;
      }
    }

    .RIGHT_SIDE_IMG {
      top: -163px;

      .paint1 {
        right: 40%;
        top: 56%;
        width: 103px;
        height: auto;
      }

      .book {
        top: 31%;
        right: 77%;
        width: 75px;
        height: auto;
      }

      .Star4 {
        top: 32%;
        right: 60%;
      }

      .Star6 {
        right: 30%;
      }

      .Rocket {
        bottom: -133px;
        width: 73px;
        padding-left: 0px;
        left: 300px;
        height: 267px;
      }
    }
  }

  // end HEADER
  // start WHAT IS MEEKO
  .About_Meeko_Main_Container .Center_Text .Top_About {
    .Small_Had {
      font-size: 26px;
    }

    .Big_Had {
      font-size: 49px;
    }
  }

  .About_Meeko_Main_Container .Center_Text .Pink_Box {
    margin-top: 40px;
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 222px 0px 40px 0px;
    }

    .Earth_Round_Bottom_Right {
      top: 20%;
    }

    .Rocket1 {
      position: absolute;
      bottom: -66px;
      right: 10%;
      width: 101px;
    }

    .Center_Text .Blue_Box {
      // margin-left: 0 !important;
      text-align: right;
      // margin-top: 80px;
      // margin-left: auto;
      margin: 30px auto auto auto;
    }

    .Center_Text .Orange_About_Box .Details {
      font-size: 15px;
      font-weight: 400;
      max-width: 600px;
      color: #8484a0;
      margin: 0 0 1.25rem 0;
    }

    .Big_Round_Left {
      width: 345px;
      height: 345px;
      top: 10%;
      left: -19%;
    }

    .Illustration1 {
      right: -24%;
      top: 262px;
      height: 164px;
    }

    .white_Back {
      top: 64%;
      left: 57%;
      width: 148px;
      display: none;
    }

    .Big_Round_Bottom_Left {
      bottom: 8%;
      width: 247px;
      height: 247px;
      left: -138px;

      .Illustration2 {
        height: 160px;
        right: -49%;
        top: 60px;
      }

      .white_Back2 {
        left: 62%;
        top: 41%;
        width: 148px;
        display: none;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 28%;
      right: 0;
      width: 101px;
      height: 206px;

      .Illustration3 {
        left: -81%;
        height: 157px;
        bottom: 22%;
        width: auto;
      }

      .white_Back3 {
        right: 21%;
        top: 33%;
        width: 148px;
        display: none;
      }
    }

    .child1 {
      position: absolute;
      top: 28%;
      right: 21%;
      width: 51px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        bottom: 0;
        width: auto;
        height: 300px;
      }

      .earth1 {
        right: 0;
        top: 93px;
        width: auto;
        left: 82px;
        height: 100px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 49%;
      width: 88px;
    }
  }

  // end WHAT IS MEEKO
  // categories start
  .Hexagonal {
    width: 193px;
  }

  .Hexagonal.Hexagonal_image {
    width: 300px;
    margin-bottom: -35px;
  }

  .Categories {
    .Paint_Bruch {
      bottom: 17%;
      left: 0%;
      width: 117px;
    }

    .shoes_boy {
      bottom: -57px;
      right: 7%;
      width: 117px;
    }

    .book_polygon {
      .Polygon1 {
        width: 100px;
        left: 27px;
        bottom: 8px;
      }

      .book {
        left: 49px;
        top: 25px;
        width: 56px;
      }

      .Polygon2 {
        bottom: 120px;
        left: -20px;
        width: 71px;
      }
    }

    .Hexagonal_Container {
      .Hexagonal_for_media {
        float: none;
      }

      .Hexa_Cont:nth-child(5) {
        margin-left: 3% !important;
      }

      .hexa {
        width: 74px;
        height: 74px;
        object-fit: contain;
      }

      .Hexa_content_Cont p {
        color: #fff;
        font-size: 17px !important;
      }

      .Hexa_Cont_same {
        display: flex;
        margin: 0px 0px 0px 0px;
      }

      .Top_Hexagonal_Container {
        width: 100%;
        margin: 0 auto;
        margin-left: 38px;

        .Hexa_Cont_same {
          margin: 0px;
        }

        .Hexagonal_for_media {
          margin: 0px 0px 9px -65px;
          display: flex;
          justify-content: center;
        }
      }

      .Bottom_Hexagonal_Container {
        .Hexa_Cont_same {
          display: flex;
          margin-left: 0px !important;
          margin-right: 0px !important;
          margin: 46px 0px;
        }
      }
    }
  }

  //  .Hexagonal_Container .Hexa_Cont .Hexa_content_Cont
  //   .Categories {
  //     .Paint_Bruch {
  //       bottom: 15%;
  //       left: -5%;
  //     }
  //     .book_polygon {
  //       right: 20px;
  //       bottom: 218px;
  //       .Polygon1 {
  //         width: 113px;
  //         top: -34px;
  //         left: 50px;
  //       }
  //       .book {
  //         left: 77px;
  //         top: 2px;
  //         width: 66px;
  //       }
  //       .Polygon2 {
  //         bottom: 151px;
  //         left: 10px;
  //         width: 83px;
  //       }
  //     }
  //   }
  //   .Categories .Hexagonal_Container .Hexagonal_media {
  //     display: block;
  //   }
  //   .Categories .Hexagonal_Container .Top_Hexagonal_Container {
  //     margin-bottom: 60px;
  //     .Hexagonal_for_media {
  //       display: none;
  //     }
  //   }
  //   .Categories .Hexagonal_Container .Bottom_Hexagonal_Container {
  //     margin-left: 2%;
  //   }
  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Top_Cont p {
    font-size: 53px;
  }

  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 73px;
      height: 73px;
    }
  }

  .Blue_Back_Container .Top_Cont {
    top: 0;
    // padding-bottom: 102px;
    margin-top: -30px;
  }

  .Become_Meekonian_Cont .Become_Cont {
    margin-top: -54px;

    .Round_Blue_Left {
      left: -9%;
      bottom: -25%;
    }
  }

  .Become_Meekonian_Cont
    .Become_Cont
    .Content_Container_Main
    .Sub_Content_Container {
    margin-right: 10px;

    .Round_Cont {
      width: 100px;
      height: 100px;
    }

    .Round_Cont img {
      width: 80px;
      height: 80px;
    }

    p {
      font-size: 19px;
      margin-bottom: 20px;
    }
  }

  // end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    // width: 20px;
    width: 30%;
    margin-top: 50px;

    p {
      font-size: 20px;
    }
  }

  .Why_Meeko .why_Meeko_Container {
    // .Blue_Cont {
    //     margin-left: 32px;
    // }
    // // .Pink_Cont {
    // //     margin-left: 18px;
    // // }
    // .Yellow_Cont {
    //     margin-left: 16px;
    // }
    // .BluePink_Cont {
    //     margin-right: 25px;
    // }
  }

  .Why_Meeko {
    .Top_Cont {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 60px;
      position: absolute;
      top: 0;
    }

    .Why5 {
      left: 1%;
      height: 129px;
      bottom: 0%;
    }

    .whiteflow1 {
      left: 3%;
      top: 16%;
      width: 260px;
    }

    .whiteflow2 {
      left: 2%;
      top: 69%;
      width: 263px;
    }

    .whiteflow3 {
      right: 0%;
      top: 64%;
      width: 227px;
      left: 67%;
    }

    .Why6 {
      right: 3%;
      height: 191px;
      bottom: 28%;
    }

    .Why7 {
      right: 1%;
      height: 217px;
      bottom: -11px;
    }

    .Why11 {
      top: 66%;
      left: 21%;
      width: auto;
      height: 40px;
    }

    .Why13 {
      right: 4%;
    }

    .Why3_Container {
      right: 6%;
      width: 155px;
      height: 155px;
      top: 34%;

      .Why3 {
        height: 133px;
      }

      .Why10 {
        left: -210px;
        width: 27px;
        height: 27px;
        bottom: -42px;
      }
    }

    .Why4_Container {
      width: 140px;
      height: 140px;
      left: 2%;
      top: 46%;

      .Why4 {
        height: 144px;
        top: -5px;
        right: 10px;
      }

      .Why9 {
        left: 62px;
        width: 100%;
        height: 54px;
        object-fit: scale-down;
      }
    }

    .Why1 {
      height: 165px;
      left: 7%;
      top: 19%;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }

    .Why_Meeko .Join_Btn {
      margin-top: 49px;
    }
  }

  .Why_Meeko .Why2 {
    right: 23%;
    width: 10%;
    top: 12%;
  }

  // end why meeko
  .About_Banner {
    padding: 20px 20px;
  }

  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     display: block;
  //     .lendo-grid-items {
  //         position: relative;
  //         margin: 0px 85px;
  //         .Join_Btn {
  //             left: -39px;
  //             margin-top: 11px;
  //         }
  //         .LendoImage {
  //             width: 473px;
  //             margin: auto;
  //         }
  //     }
  // }
  .lendo-page-alignment {
    padding: 40px 0px 0;

    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;
      grid-template-columns: 300px minmax(0, 1fr);

      .lendo-grid-items {
        .icon-text-grid {
          margin-bottom: 0.5rem;

          .icon-text-grid-items span {
            font-size: 15px !important;
          }
        }

        .Join_Btn {
          left: 13px;
          margin-top: 20px;
          padding: 6px 13px;
          font-size: 13px;
        }
      }

      img.LendoImage {
        width: auto;
        height: 180px;
        margin: auto 20px auto auto;
      }
    }
  }

  // end LEADO
  // start Banner
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    left: 9.8%;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 30px;
      padding: 0 100px;
    }
  }

  .About_Banner .Text {
    width: 76%;
  }

  // end Banner
  //start SLIDER
  .Slider_Container_Main {
    .slick-prev i,
    .slick-next i {
      width: 50px;
      height: 50px;
      font-size: 40px;
      padding: 4px 18px;
    }

    .slick-prev i {
      left: 350px;
    }

    .slick-next i {
      right: 300px;
    }

    .slick-prev {
      display: flex !important;
      justify-content: center;
    }

    .slick-next {
      display: flex !important;
      justify-content: center;
    }

    .Slider_image {
      .right_slider_image img {
        width: 70px;
        height: 70px;
      }

      .profile4 {
        left: -53px;
      }

      .left_slider_image img {
        width: 70px;
        height: 70px;
      }
    }

    .Slider_Box_Main {
      .Slider_Box {
        width: 800px;
        padding: 20px 50px 84px;
        margin: 12px auto;

        .slick-slide {
          padding: 0px;
        }
      }

      .inner_Slider_Box {
        width: 600px !important;
        padding: 30px 40px;
      }

      .Slider_Box {
        .slick-slide::after {
          width: 570px;
          left: 9%;
          bottom: 2px;
        }

        .slick-slide::before {
          width: 530px;
          left: 12%;
          bottom: -17px;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        right: -63%;
        top: 68px;

        .profile2 {
          right: -17%;
          top: 23px;
        }
      }

      .right_slider_image {
        left: -48%;
        top: 93px;

        .profile5 {
          left: -21%;
        }
      }
    }
  }

  // end SLIDER
  // start FOOTER
  .landing-footer-alignment {
    padding: 25px 0;

    .footer-grid {
      margin-left: 0;
      display: block;
      text-align: center;

      .footer-grid-items {
        .Social_Icon {
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          margin-top: 0px;
        }
      }

      .landing-footer-alignment
        .footer-grid
        .footer_second_col
        ul
        li:not(:last-of-type)::after {
        margin: 0px 4px;
      }

      .footer_second_col {
        padding: 20px 0px;

        p {
          font-size: 15px;
          width: 97%;
        }

        ul {
          &::after {
            width: 74%;
          }

          li {
            font-size: 18px !important;
            padding-left: 4px;
            padding-right: 4px;

            &:not(:last-of-type)::after {
              margin: 0px 4px;
            }
          }
        }
      }
    }
  }

  // end FOOTER
  .Landing_Page_Blue_Background {
    height: 700px;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .Blue_Back_Container.try_demo_class_card_section .Common_Card_Container,
  .Blue_Back_Container.popular_class_box_section .Common_Card_Container {
    max-width: 350px !important;
    width: 350px;

    .Card_Image_Container {
      // height: 155px;
    }
  }
}

@media screen and (min-width: 901px) and (max-width: 1025px) {
  .Blue_Back_Container.try_demo_class_card_section .Common_Card_Container,
  .Blue_Back_Container.popular_class_box_section .Common_Card_Container {
    max-width: 350px !important;
    width: 350px;

    .Card_Image_Container {
      // height: 150px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1140px) {
  .back_to_top_btn {
    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .Landing_Page_Blue_Background {
    @keyframes fly {
      0% {
        transform: translateY(70vh);
      }

      100% {
        transform: translateY(-150vh);
      }
    }

    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: 465px;
          width: 70%;
          margin: 0% auto;

          .play_Btn {
            width: 55px;
            height: 55px;

            i {
              font-size: 25px;
            }
          }
        }
      }

      video.Card_Img {
        // object-fit: contain;
        // height: 390px;
      }
    }
  }

  .css-1392reu-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 981px) and (max-width: 1024px) {
  .Landing_Page_Blue_Background {
    .RIGHT_SIDE_IMG {
      .Rocket {
        bottom: -194px !important;
        width: 173px !important;
        left: 308px !important;
        padding-left: 40px !important;
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1025px) {
  .Landing_Page_Background {
    @keyframes fly {
      0% {
        transform: translateY(70vh);
      }

      100% {
        transform: translateY(-150vh);
      }
    }

    .Banner_Container {
      .Video_Banner {
        .slick-slider {
          .slick-arrow {
            display: block !important;
          }

          .slick-prev {
            i {
              width: 55px;
              height: 55px;
              padding: 0px 15px;
              left: 29px;
              top: -17px;
            }
          }

          .slick-next {
            i {
              width: 55px;
              height: 55px;
              right: 39px;
              top: -17px;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }

  .back_to_top_btn {
    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .Become_Meekonian_Cont {
    .Become_Cont {
      .Content_Container_Main {
        .Next_arrow img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }
    }
  }

  .css-1392reu-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
    flex-wrap: nowrap !important;
  }

  .lendo-page-alignment .lendo_title {
    font-size: 24px;
  }

  .Slider_Container_Main.review_slider_section {
    margin-bottom: 150px;

    .slick-next i {
      right: 275px;
    }

    .slick-prev i {
      left: 335px;
      padding: 4px 23px;
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Bottom_Container .Card_Bottom {
        padding: 8px;
      }

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  .Categories_main_section {
    padding-top: 0;
    margin-top: -70px;
  }

  .popular_class_box_section {
    padding-top: 15px;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: -75px;
  }

  .Become_Meekonian_Cont .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Categories .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Ultra_Pro_section .Ultra_Pro_title_row .Top_Cont p {
    font-size: 50px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 60px 80px;
    background-position: 135% -20%;
    background-size: auto 760px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 492px;

        p {
          // font-size: 40px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 70% 30%;

      .Ultra_Pro_col_01 {
        grid-template-columns: 70% 30%;

        .Ultra_Pro_btn_block .btn_outter {
          a.btn_inner {
            padding: 3px;
            width: 190px;
          }

          a.btn_inner img {
            width: auto;
            height: 46px;
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 28px;
            padding-bottom: 10px;
          }

          .Ultra_Pro_big_title {
            font-size: 40px;
          }

          ul.Ultra_Pro_text li.Ultra_Pro_li {
            font-size: 20px;
            padding-bottom: 0;
          }
        }
      }

      .Ultra_Pro_col_02 {
        .ultra_mobile_cell {
          width: auto;
          height: 460px;
        }

        img.ab_ultra_onlinelaunching {
          right: -18%;
          width: auto;
          height: 57px;
        }

        img.ab_ultra_play {
          right: -5%;
          top: -12%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: -20%;
          width: auto;
          height: 70px;
        }
      }
    }
  }

  // END
  // NEW CATEGORY SECTION
  .Categories_main_section {
    img.Round_red {
      top: -3%;
      right: 6%;
      width: auto;
      height: 135px;
    }

    .hexa_section {
      width: 940px;

      .hexa_row {
        .hexa_col {
          img.shoes_boy {
            width: auto;
            height: 110px;
          }

          .book_polygon {
            left: 5%;
            bottom: -28%;

            img.Polygon2 {
              top: -43%;
              left: 85%;
              width: auto;
              height: 85px;
            }

            img.Polygon1 {
              width: auto;
              height: 135px;
            }
          }

          .Hexa_Cont {
            height: 190px;
            width: 165px;
            background-size: auto 180px;

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 50px;
              }

              .cate_name_text {
                font-size: 15px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 230px;
        width: 230px;
        top: -47px;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 219px;
      }
    }
  }

  // END
  .Banner_Container .Video_Banner .Item {
    height: 35vh;
  }

  .Why_Meeko {
    margin-bottom: 100px;

    .why_Meeko_Container {
      margin-top: -80px;
    }

    img.why_meeko {
      width: auto;
      height: 150px;
    }

    .Why11 {
      left: 29%;
    }

    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 18%;
      }

      .Text_Container_02 {
        right: 23%;
        margin-top: 35px;
      }

      .Text_Container_03 {
        left: 20%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 23%;
      }

      .Text_Container_05 {
        left: 23%;
        position: relative;
      }

      .Text_Container_06 {
        right: 21%;
        margin-top: 55px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why4_Container {
      .Why9 {
        left: 135px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont {
    margin-top: -40px;

    .Round_Blue_Left {
      width: 260px;
      height: 270px;
      bottom: -13%;
    }

    .Round_Blue_Right {
      width: 150px;
      height: 310px;
    }

    .Become_Cont {
      padding: 40px 21px;
    }

    .Top_Cont {
      padding-bottom: 80px;
    }
  }

  .Landing_Page_Blue_Background {
    height: 700px;
  }

  .Categories {
    padding-top: 25px;
  }

  .About_Meeko_Main_Container {
    margin-top: 0;

    .Center_Text {
      .Blue_Box {
        margin-top: 50px !important;
      }

      .Pink_Box {
        margin-top: 60px !important;
      }

      .Orange_About_Box {
        width: 70%;
        margin: auto;
      }
    }
  }

  .Blue_Back_Container.try_demo_class_card_section,
  .Blue_Back_Container.popular_class_box_section {
    .Common_Card_Container {
      max-width: 280px;

      .Card_Image_Container {
        // height: 170px;
      }
    }
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    left: 30%;
    width: 78px;
    height: 78px;
    top: 43%;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 77px;
    height: 77px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -15%;
    width: 175px;
    height: 175px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 100px;
    height: 100px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image {
    top: -44%;
  }

  //start HEADER

  .Landing_Page_Blue_Background {
    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: 320px;
          width: 70%;
          margin: 0% auto;

          .play_Btn {
            i {
              font-size: 25px;
              width: 65px;
              height: 65px;
            }
          }
        }
      }

      video.Card_Img {
        // object-fit: contain;
        // height: 390px;
      }
    }
  }

  .css-k7p11r-indicatorContainer {
    padding-left: 72px !important;
  }

  .css-vps8j1-indicatorContainer {
    padding-left: 72px !important;
  }

  .css-1392reu-control {
    padding-left: 5px !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 126px;
      height: 51px;
    }

    li a {
      font-size: 14px;
      margin-right: 13px;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 14px;
      margin-left: 11px;
    }

    .menu {
      margin-left: 209px;
    }

    .logo {
      left: -163px;
      width: 383px;
      height: 383px;

      img {
        right: 17%;
        bottom: 19%;
        width: 144px;
      }
    }
  }

  .Landing_Page_Blue_Background {
    .Center_Video_Player {
      bottom: -1%;
      height: 400px;
      width: 100%;
    }

    .Center_Video_Player::before {
      left: -12%;
      right: -22%;
    }

    .RIGHT_SIDE_IMG .Right_Box {
      width: 249px;
      top: 35%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -191px;

      img {
        width: 115px;
      }

      .Star1 {
        left: 28%;
        width: 25px;
        bottom: 21%;
      }

      .camera {
        bottom: 3%;
      }
    }

    .RIGHT_SIDE_IMG {
      top: -157px;

      .paint1 {
        right: 60%;
        top: 44%;
        width: 74px;
        height: auto;
      }

      .book {
        top: 34%;
        right: 75%;
        width: 50px;
        height: auto;
      }

      .Star4 {
        top: 34%;
        right: 60%;
      }

      .Rocket {
        bottom: -194px;
        width: 187px;
        left: 308px;
        padding-left: 60px;
      }
    }
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 539px !important;
    height: 446px !important;
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  // end HEADER
  // start WHAT IS MEEKO
  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 100px 0px 30px 0px;
    }

    .Rocket1 {
      position: absolute;
      bottom: -66px;
      right: 10%;
      width: 113px;
    }

    .Center_Text .Blue_Box {
      // margin-left: 0 !important;
      text-align: right;
      // margin-top: 80px;
      // margin-left: auto;
      margin: auto;
    }

    .Center_Text .Orange_About_Box .Details {
      font-size: 16px;
      font-weight: 400;
      max-width: 600px;
      color: #8484a0;
      margin: 0 0 1.25rem 0;
    }

    .Big_Round_Left {
      width: 404px;
      height: 404px;
      top: 0%;
      left: -19%;
    }

    .Illustration1 {
      right: -37%;
      top: 55%;
      height: 180px;
    }

    .white_Back {
      top: 55%;
      left: 64%;
      width: 192px;
    }

    .Big_Round_Bottom_Left {
      bottom: 0;
      width: 311px;
      height: 311px;
      left: -138px;

      .Illustration2 {
        height: 197px;
        right: -39%;
        top: 17%;
      }

      .white_Back2 {
        left: 60%;
        top: 20%;
        width: 192px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 16%;
      right: 0;
      width: 174px;
      height: 331px;

      .Illustration3 {
        left: -36%;
        height: 190px;
        bottom: 45%;
        width: auto;
      }

      .white_Back3 {
        right: 25%;
        top: 5%;
        width: 192px;
      }
    }

    .child1 {
      position: absolute;
      top: 24%;
      right: 16%;
      width: 100px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: 0;
        width: auto;
        height: 300px;
      }

      .earth1 {
        position: absolute;
        right: 0;
        top: 32%;
        width: auto;
        height: 100px;
        left: 80px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 50%;
      width: 135px;
    }
  }

  // end WHAT IS MEEKO
  // categories start
  .Categories .Hexagonal_Container {
    margin-left: 0px;
  }

  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      width: 90%;
      margin-left: 121px;
      margin-top: 23px;

      .Hexagonal_image {
        position: absolute;
        top: 37px;
        width: 243px;
        right: 97%;
      }

      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 10px;

        .Hexa_content_Cont {
          margin: 6px 5px 8px 16px;

          p {
            color: #fff;
            font-size: 16px;
          }
        }

        .hexa {
          width: 68px;
        }

        .Hexagonal {
          width: 181px;
        }
      }
    }
  }

  .Categories .Hexagonal_Container .Bottom_Hexagonal_Container div {
    margin: 13px;
  }

  .Categories {
    .Round_red {
      top: -25%;
      right: 5%;
    }

    .Paint_Bruch {
      bottom: -5%;
      left: 0%;
      width: 100px;
    }

    .shoes_boy {
      bottom: -117px;
      width: 121px;
    }
  }

  .Categories .Hexagonal_Container .Hexa_Cont .Hexa_content_Cont {
    img {
      width: 85%;
    }

    p {
      font-size: 18px;
    }
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    .Hexa_Cont {
      margin: 0 11px;

      .Hexagonal {
        width: 185px;
      }
    }
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    flex-wrap: nowrap;

    .Hexagonal_image {
      position: absolute;
      top: -200px;
      width: 243px;
      left: -89px;
    }
  }

  .Categories .Hexagonal_Container .Bottom_Hexagonal_Container {
    margin-left: -168px;
    margin-top: -44px;

    .Hexagonal {
      width: 185px;
    }
  }

  .Categories .book_polygon {
    .Polygon1 {
      left: 41px;
      top: 0px;
      width: 113px;
    }

    .book {
      position: absolute;
      left: 72px;
      top: 43px;
      width: 55px;
    }

    .Polygon2 {
      position: relative;
      bottom: 128px;
      left: -4px;
      width: 70px;
    }
  }

  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 83px;
      height: 86px;
    }
  }

  .Blue_Back_Container .Top_Cont {
    top: 0;
    // padding-bottom: 102px;
    margin-top: -30px;
  }

  .Become_Meekonian_Cont .Become_Cont {
    margin-top: -54px;

    .Round_Blue_Left {
      left: -9%;
      bottom: -15%;
    }
  }

  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Sub_Content_Container {
      width: 235px;

      .Round_Cont {
        width: 170px;
        height: 170px;
      }

      .Round_Cont img {
        width: 115px;
        height: 115px;
      }

      p {
        font-size: 19px;
        margin-bottom: 20px;
      }
    }
  }

  // end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 40%;
    margin-top: 60px;

    p {
      font-size: 22px;
    }
  }

  .Why_Meeko {
    .Top_Cont {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      position: absolute;
      top: 2px;
    }

    .Why5 {
      left: 7%;
      height: 145px;
      bottom: 12%;
    }

    .whiteflow1 {
      left: 3%;
      top: 16%;
      width: 260px;
    }

    .whiteflow2 {
      left: 5%;
      top: 59%;
    }

    .whiteflow3 {
      right: 0%;
      top: 50%;
      width: 279px;
    }

    .Why6 {
      right: 1%;
      height: 197px;
      bottom: 22%;
    }

    .Why7 {
      right: 3%;
      height: 223px;
      bottom: -52px;
    }

    .Why13 {
      right: 4%;
    }

    .Why3_Container {
      right: 6%;
      width: 160px;
      height: 160px;
      top: 35%;

      .Why3 {
        height: 216px;
      }

      .Why10 {
        left: -207px;
        width: 30px;
        height: 30px;
      }
    }

    .Why4_Container {
      width: 170px;
      height: 170px;
      left: 2%;
      top: 46%;

      .Why4 {
        height: 155px;
        top: 2px;
        right: 12px;
      }

      .Why9 {
        left: 67px;
        width: 100%;
        height: 54px;
        object-fit: scale-down;
      }
    }

    .Why1 {
      height: 194px;
      left: 5%;
      top: 21%;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }
  }

  .Why_Meeko .Why2 {
    right: 29%;
    width: 8%;
  }

  // end why meeko
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     display: block;
  //     .lendo-grid-items {
  //         position: relative;
  //         margin: 0px 135px;
  //         .Join_Btn {
  //             left: -15px;
  //         }
  //     }
  // }
  .lendo-page-alignment {
    padding: 40px 20px 0;

    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;
      grid-template-columns: 440px minmax(0, 1fr);

      .lendo-grid-items {
        .icon-text-grid {
          margin-bottom: 0.5rem;

          .icon-text-grid-items span {
            font-size: 15px !important;
          }
        }

        .Join_Btn {
          left: 40px;
          margin-top: -8px;
        }
      }

      img.LendoImage {
        width: auto;
        height: 220px;
        margin: auto 30px auto auto;
      }
    }
  }

  // end LEADO
  // start Banner
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    left: 9.8%;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 35px;
      padding: 0 100px;
    }
  }

  .About_Banner .Text {
    width: 76%;
  }

  // end Banner
  //start SLIDER
  .Slider_Container_Main {
    .slick-prev i {
      left: 320px;
    }

    .slick-next i {
      right: 250px;
    }

    .slick-prev {
      display: flex !important;
      justify-content: center;
    }

    .slick-next {
      display: flex !important;
      justify-content: center;
    }

    .Slider_image {
      .right_slider_image img {
        width: 84px;
        height: 84px;
      }

      .left_slider_image img {
        width: 84px;
        height: 84px;
      }
    }

    .Slider_Box_Main {
      .Slider_Box {
        width: 790px;
        padding: 20px 50px 84px;
        margin: 12px auto;

        .slick-slide {
          padding: 0px;
        }
      }

      .inner_Slider_Box {
        width: 600px !important;
      }

      .Slider_Box {
        .slick-slide::after {
          width: 570px;
          left: 9%;
          bottom: 6px;
        }

        .slick-slide::before {
          width: 550px;
          left: 10%;
          bottom: -10px;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        right: -63%;
        top: 68px;

        .profile2 {
          right: 81%;
        }
      }

      .right_slider_image {
        left: -48%;
        top: 93px;

        .profile5 {
          left: 86%;
        }
      }
    }
  }

  // end SLIDER
  // start FOOTER
  .landing-footer-alignment {
    padding: 56px 0;

    .footer-grid {
      margin-left: 0;
      display: block;
      text-align: center;

      .footer-grid-items {
        .Social_Icon {
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
        }
      }

      .footer_second_col {
        padding: 37px 0px;

        p {
          font-size: 15px;
        }

        ul {
          &::after {
            width: 59%;
          }

          li {
            font-size: 18px !important;
            padding-left: 10px;
            padding-right: 9px;
          }
        }
      }
    }
  }

  // end FOOTER
}

@media screen and (min-width: 1026px) and (max-width: 1140px) {
  .landing-header {
    .header-alignment {
      .login {
        .css-1wa3eu0-placeholder {
          font-size: 13px;
          margin-left: 3px;
        }

        .css-1392reu-control {
          height: 51px !important;
          width: 150px !important;
        }

        .css-3xnrlj-control {
          height: 51px !important;
          width: 164px !important;
        }
      }
    }
  }

  .lendo-page-alignment .lendo_title {
    font-size: 26px;
  }

  .Slider_Container_Main.review_slider_section {
    .slick-next i {
      right: 260px;
    }

    .slick-prev i {
      left: 325px;
      padding: 4px 23px;
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  .popular_class_box_section {
    padding-top: 25px;
  }

  .Categories.Categories_head_section {
    padding-top: 0;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
  }

  .Become_Meekonian_Cont .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Categories .Top_Cont p,
  .Blue_Back_Container .Top_Cont p,
  .Ultra_Pro_section .Ultra_Pro_title_row .Top_Cont p {
    font-size: 50px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 60px 80px;
    background-position: 126% 110%;
    background-size: auto 825px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 492px;
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 66% 34%;

      .Ultra_Pro_col_01 {
        grid-template-columns: 70% 30%;

        .Ultra_Pro_btn_block .btn_outter {
          a.btn_inner {
            padding: 3px;
            width: 190px;
          }

          a.btn_inner img {
            width: auto;
            height: 46px;
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 28px;
            padding-bottom: 10px;
          }

          .Ultra_Pro_big_title {
            font-size: 40px;
          }

          ul.Ultra_Pro_text li.Ultra_Pro_li {
            font-size: 20px;
            padding-bottom: 0;
          }
        }
      }

      .Ultra_Pro_col_02 {
        .ultra_mobile_cell {
          width: auto;
          height: 570px;
        }

        img.ab_ultra_onlinelaunching {
          right: -18%;
          width: auto;
          height: 57px;
        }

        img.ab_ultra_play {
          right: -5%;
          top: -12%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: -20%;
          width: auto;
          height: 70px;
        }
      }
    }
  }

  // END
  // NEW CATEGORY SECTION
  .Categories_main_section {
    img.Round_red {
      top: -3%;
      right: 6%;
      width: auto;
      height: 135px;
    }

    .hexa_section {
      width: 920px;

      .hexa_row {
        .hexa_col {
          img.shoes_boy {
            width: auto;
            height: 110px;
          }

          .book_polygon {
            left: 5%;
            bottom: -28%;

            img.Polygon2 {
              top: -43%;
              left: 85%;
              width: auto;
              height: 85px;
            }

            img.Polygon1 {
              width: auto;
              height: 135px;
            }
          }

          .Hexa_Cont {
            height: 190px;
            width: 165px;
            background-size: auto 180px;

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 50px;
              }

              .cate_name_text {
                font-size: 15px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 230px;
        width: 230px;
        top: -47px;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 219px;
      }
    }
  }

  // END
  .Blue_Back_Container .Card_Container {
    .slick-next i,
    .slick-prev i {
      padding: 4px 19px;
      width: 50px;
      height: 50px;
      font-size: 40px;
    }

    .slick-prev i {
      left: 13px;
    }
  }

  .Why_Meeko {
    margin-bottom: 100px;

    .why_Meeko_Container {
      margin-top: -80px;
    }

    img.why_meeko {
      width: auto;
      height: 150px;
    }

    .Why11 {
      left: 29%;
    }

    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 18%;
      }

      .Text_Container_02 {
        right: 10%;
        width: 45%;
        margin-top: 35px;
      }

      .Text_Container_03 {
        left: 13%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 23%;
      }

      .Text_Container_05 {
        left: 23%;
        position: relative;
      }

      .Text_Container_06 {
        right: 21%;
        margin-top: 55px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why4_Container {
      .Why9 {
        left: 135px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont {
    margin-top: -95px;

    .Round_Blue_Left {
      width: 260px;
      height: 270px;
      bottom: -13%;
    }

    .Round_Blue_Right {
      width: 150px;
      height: 310px;
    }

    .Become_Cont {
      padding: 40px 21px;
    }

    .Top_Cont {
      padding-bottom: 80px;
      padding-top: 25px;
    }
  }

  .Categories {
    padding-top: 0;
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container .Hexa_Cont_same {
    margin-left: 13px !important;
  }

  .Landing_Page_Blue_Background {
    height: 700px;
  }

  .Blue_Back_card_section .Common_Card_Container {
    max-width: 290px;
  }

  .About_Meeko_Main_Container .Center_Text {
    // .Blue_Box {
    //     margin-top: 40px !important;
    // }
    .Pink_Box {
      margin-top: 100px !important;
    }

    .Orange_About_Box {
      margin-top: 50px !important;
      width: 75%;
      margin: auto;
    }
  }

  .Blue_Back_Container {
    .Common_Card_Container {
      max-width: 290px;

      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  // ==== Header
  .landing-header .header-alignment .logo img {
    width: 135px;
    height: 31px;
    right: 25%;
  }

  .landing_header_scroll.landing-header .header-alignment .logo img {
    width: 95px;
    height: 34px;
  }

  .landing_header_scroll.landing-header .header-alignment .logo {
    left: -195px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li
    a.active_nav_Side {
    width: 134px;
    height: 43px;
    font-size: 14px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li:last-child
    a {
    width: 134px;
    height: 43px;
    font-size: 14px;
  }

  .landing-header .header-alignment .login {
    .css-vps8j1-indicatorContainer {
      padding-right: 17px !important;
    }

    .css-3xnrlj-control {
      width: 150px !important;
    }
  }

  .landing-header .header-alignment .login .css-k7p11r-indicatorContainer {
    padding-right: 17px !important;
  }

  .landing_header_scroll .css-1392reu-control {
    height: 47px;
  }

  .landing_header_scroll .css-1wa3eu0-placeholder {
    top: 45%;
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    left: 30%;
    width: 95px;
    height: 95px;
    top: 43%;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 104px;
    height: 104px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -14%;
    width: 175px;
    height: 175px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 100px;
    height: 100px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
    left: 13%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
    right: 3%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player .child-image {
    top: -39%;
  }

  // ================= Why IS mEeko ==============================
  img.Blue_Wawe {
    width: 164px;
  }

  // HEADER
  .Landing_Page_Blue_Background .Center_Video_Player video.Card_Img {
    // object-fit: contain;
    // height: 441px;
  }

  .Center_Video_Player_content .Card_Image_Container .play_Btn {
    width: 60px;
    height: 60px;
    top: 53%;

    i {
      font-size: 25px;
    }
  }

  .css-k7p11r-indicatorContainer {
    padding-left: 72px !important;
  }

  .css-vps8j1-indicatorContainer {
    // padding-left: 72px !important;
  }

  .css-1392reu-control {
    padding-left: 5px !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 564px !important;
    height: 446px !important;
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 126px;
      height: 51px;
    }

    li a {
      margin-right: 13px;
      width: 126px;
      height: 45px;
      font-size: 13px !important;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 20px;
      margin-left: 11px;
    }

    .menu {
      margin-left: 132px;
    }

    .logo {
      left: -163px;
      top: 40px;
      // width: 383px;
      // height: 383px;
    }
  }

  .Landing_Page_Blue_Background {
    // top: -122px;
    height: 870px;

    .Center_Video_Player {
      bottom: -13%;
      height: 450px;
      width: 100%;
    }

    .RIGHT_SIDE_IMG .Right_Box {
      width: 249px;
      top: 33%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -187px;

      .paint {
        position: absolute;
        top: 41%;
        left: 66%;
        width: 88px;
      }
    }

    .RIGHT_SIDE_IMG {
      top: -137px;

      .paint1 {
        left: 22%;
        right: 0;
        top: 43%;
        width: 79px;
        height: auto;
      }

      .book {
        position: absolute;
        top: 30%;
        left: 9%;
        width: 55px;
        right: 0;
        height: auto;
      }

      .Star4 {
        top: 34%;
        right: 60%;
      }

      .Rocket {
        bottom: -132px;
        padding-left: 285px;
        height: 343px;
      }
    }
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 154px 0px 0px 0px;
    }

    .Rocket1 {
      position: absolute;
      bottom: -43px;
      right: 10%;
      width: 102px;
    }

    .Center_Text .Blue_Box {
      // margin-left: 0 !important;
      text-align: right;
      // margin-top: 80px;
      // margin-left: auto;
      margin: 50px auto 0 auto !important;
    }

    .Center_Text .Orange_About_Box .Details {
      font-size: 16px;
      font-weight: 400;
      max-width: 600px;
      color: #8484a0;
      margin: 0 0 1.25rem 0;
    }

    .Big_Round_Left {
      width: 300px;
      height: 300px;
      top: 13%;
      left: -14%;
    }

    .Illustration1 {
      right: -48%;
      top: 60%;
      height: 170px;
    }

    .white_Back {
      top: 65%;
      left: 64%;
      width: 192px;
    }

    .Big_Round_Bottom_Left {
      bottom: 0;
      width: 295px;
      height: 295px;
      left: -143px;

      .Illustration2 {
        height: 204px;
        right: -49%;
        top: 10%;
      }

      .white_Back2 {
        left: 62%;
        top: 20%;
        width: 192px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 21%;
      right: 0;
      width: 180px;
      height: 313px;

      .Illustration3 {
        left: -34%;
        height: 205px;
        bottom: 20%;
        width: auto;
      }

      .white_Back3 {
        right: 21%;
        top: 25%;
        width: 192px;
      }
    }

    .child1 {
      position: absolute;
      top: 21%;
      right: 17%;
      width: 75px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: -85px;
        width: auto;
      }

      .earth1 {
        position: absolute;
        right: 0;
        top: 53%;
        width: auto;
        height: 125px;
        left: 88px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 56%;
      width: auto;
      height: 150px;
    }
  }

  // categories start
  .Categories .Hexagonal_Container .Bottom_Hexagonal_Container div {
    margin: 13px;
  }

  .Categories {
    .Round_red {
      top: -25%;
      right: 5%;
    }

    .Paint_Bruch {
      bottom: 10%;
      left: 0%;
      width: 118px;
    }

    .shoes_boy {
      bottom: -117px;
      width: 112px;
    }
  }

  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      .Hexagonal_image {
        position: absolute;
        top: 37px;
        width: 243px;
        right: 97%;
      }

      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 0 10px;

        .Hexa_content_Cont {
          margin: 6px 5px 8px 16px;

          p {
            color: #fff;
            font-size: 16px;
          }
        }

        .hexa {
          width: 68px;
        }

        .Hexagonal {
          width: 181px;
        }
      }
    }
  }

  // .Categories .Hexagonal_Container .Hexa_Cont .Hexa_content_Cont {
  //   img {
  //     width: 85%;
  //   }
  //   p {
  //     font-size: 18px;
  //   }
  // }
  // .Categories .Hexagonal_Container .Top_Hexagonal_Container {
  //   .Hexa_Cont {
  //     margin: 11px;
  //     .Hexagonal {
  //       width: 185px;
  //     }
  //   }
  // }
  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    flex-wrap: nowrap;
    margin-left: 20%;
  }

  // .Categories .Hexagonal_Container .Bottom_Hexagonal_Container {
  //   margin-left: -168px;
  //   margin-top: -44px;
  //   .Hexagonal {
  //     width: 185px;
  //   }
  // }
  .Categories .book_polygon {
    .Polygon1 {
      left: 41px;
      top: 0px;
      width: 113px;
    }

    .book {
      position: absolute;
      left: 72px;
      top: 43px;
      width: 55px;
    }

    .Polygon2 {
      position: relative;
      bottom: 128px;
      left: -4px;
      width: 70px;
    }
  }

  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 51px;
      height: 51px;
    }
  }

  .Blue_Back_Container {
    .Card_Container {
      padding: 40px 25px;

      .slick-prev {
        left: -60px;
      }

      .slick-next {
        right: -15px;
      }
    }
  }

  .Blue_Back_Container .Top_Cont {
    // top: -44px;
    // padding-bottom: 102px;
    margin-top: -65px;
  }

  .Become_Meekonian_Cont .Become_Cont {
    margin-top: -54px;

    .Round_Blue_Left {
      left: -9%;
      bottom: -25%;
    }
  }

  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow img {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }

    .Sub_Content_Container {
      width: 108px;

      .Round_Cont {
        width: 140px;
        height: 140px;
      }

      .Round_Cont img {
        width: 90px;
        height: 90px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  // end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 40% !important;

    p {
      font-size: 25px;
    }
  }

  .Why_Meeko {
    .Top_Cont {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      position: absolute;
      top: 0;
    }

    .Why5 {
      left: 6%;
      height: 185px;
      bottom: 12%;
    }

    .whiteflow1 {
      left: 3%;
      top: 21%;
      width: 260px;
    }

    .whiteflow2 {
      left: 3%;
      top: 62%;
    }

    .whiteflow3 {
      right: 0%;
      top: 58%;
    }

    .Why6 {
      right: 5%;
      height: 242px;
    }

    .Why7 {
      right: 2%;
      height: 282px;
      bottom: -5%;
    }

    .Why3_Container {
      right: 3%;
      width: 185px;
      height: 185px;
      top: 36%;

      .Why3 {
        height: 195px;
      }

      .Why10 {
        left: -207px;
      }
    }

    .Why4_Container {
      width: 200px;
      height: 200px;

      .Why4 {
        height: 173px;
        top: 9px;
        right: 13px;
      }
    }

    .Why1 {
      height: 180px;
      left: 3%;
      top: 24%;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }
  }

  .Why_Meeko .Why2 {
    right: 34%;
    width: 6%;
  }

  // end why meeko
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     display: block;
  //     .lendo-grid-items {
  //         position: relative;
  //         margin: 0px 156px;
  //         .Join_Btn {
  //             left: -15px;
  //         }
  //     }
  // }
  .lendo-page-alignment {
    padding: 40px 20px 0;

    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;
      grid-template-columns: 530px minmax(0, 1fr);

      .lendo-grid-items {
        .icon-text-grid {
          margin-bottom: 0.5rem;

          .icon-text-grid-items span {
            font-size: 15px !important;
          }
        }

        .Join_Btn {
          left: 40px;
          margin-top: 10px;
        }
      }

      img.LendoImage {
        width: auto;
        height: 300px;
        margin: auto 30px auto auto;
      }
    }
  }

  // end LEADO
  // start Banner
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    left: 9.8%;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 40px;
      padding: 0 110px;
    }
  }

  // end Banner
  // start SLIDER
  .Slider_Container_Main {
    display: block;

    .Slider_Box_Main {
      .Slider_Box {
        margin: 54px auto;
      }
    }
  }

  .About_Banner .Text {
    width: 76%;
  }

  // end SLIDER
  //start SLIDER
  .Slider_Container_Main {
    .slick-prev i {
      left: 309px;
    }

    .slick-next i {
      right: 270px;
    }

    .slick-prev {
      display: flex !important;
      justify-content: center;
    }

    .slick-next {
      display: flex !important;
      justify-content: center;
    }

    .Slider_image {
      .right_slider_image img {
        width: 89px;
        height: 89px;
      }

      .left_slider_image img {
        width: 89px;
        height: 89px;
      }
    }

    .Slider_Box_Main {
      .Slider_Box {
        width: 717px;
        padding: 50px 29px;
        margin: 12px auto;
      }

      .inner_Slider_Box {
        width: 597px !important;
      }

      .Slider_Box {
        .slick-slide::after {
          width: 584px;
          left: 6%;
        }

        .slick-slide::before {
          width: 573px;
          left: 7%;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        right: -63%;
        top: 68px;

        .profile2 {
          right: 81%;
        }
      }

      .right_slider_image {
        left: -82%;
        top: 93px;

        .profile5 {
          left: 86%;
        }
      }
    }
  }

  // end SLIDER
  // start FOOTER
  .landing-footer-alignment {
    .footer-grid {
      margin-left: -12px;

      .footer-grid-items {
        .Social_Icon {
          width: 100%;
          margin-left: 0px;
          margin-right: -74px;
        }
      }

      .footer_second_col {
        p {
          font-size: 14px;
        }

        ul {
          li {
            font-size: 16px !important;
            padding-left: 10px;
            padding-right: 9px;
          }
        }
      }
    }
  }

  // end FOOTER
  // .Responsive_Header_Container .Header_logo {
  //     width: 100%;
  //     display: flex;
  //     a {
  //         margin: auto;
  //     }
  // }
}

@media screen and (min-width: 1141px) and (max-width: 1280px) {
  .Landing_Page_Blue_Background {
    @keyframes fly {
      0% {
        transform: translateY(50vh);
      }

      100% {
        transform: translateY(-135vh);
      }
    }

    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: auto;
        }
      }
    }
  }

  .back_to_top_btn {
    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .lendo-page-alignment .lendo_title {
    font-size: 28px;
  }

  .Slider_Container_Main.review_slider_section {
    .slick-next i {
      right: 290px;
    }

    .slick-prev i {
      left: 345px;
      padding: 4px 23px;
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  .Categories.Categories_head_section {
    padding-top: 10px;
  }

  .popular_class_box_section {
    padding-top: 30px;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 10px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 60px 80px;
    background-position: 118% -93%;
    background-size: auto 860px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 678px;

        p {
          // font-size: 40px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 66% 34%;

      .Ultra_Pro_col_01 {
        grid-template-columns: 70% 30%;

        .Ultra_Pro_btn_block .btn_outter {
          a.btn_inner {
            padding: 3px;
            width: 190px;
          }

          a.btn_inner img {
            width: auto;
            height: 46px;
          }
        }

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 28px;
            padding-bottom: 10px;
          }

          .Ultra_Pro_big_title {
            font-size: 40px;
          }

          ul.Ultra_Pro_text li.Ultra_Pro_li {
            font-size: 20px;
            padding-bottom: 0;
          }
        }
      }

      .Ultra_Pro_col_02 {
        .ultra_mobile_cell {
          width: auto;
          height: 570px;
        }

        img.ab_ultra_onlinelaunching {
          right: -12%;
          width: auto;
          height: 66px;
        }

        img.ab_ultra_play {
          right: -5%;
          top: -12%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: -20%;
          width: auto;
          height: 70px;
        }
      }
    }
  }

  // END
  // NEW CATEGORY SECTION
  .Categories_main_section {
    img.Round_red {
      top: -3%;
      right: 5%;
    }

    .hexa_section {
      width: 1045px;

      .hexa_row {
        .hexa_col {
          .book_polygon {
            left: 5%;
            bottom: -28%;

            img.Polygon2 {
              top: -43%;
              left: 85%;
              width: auto;
              height: 100px;
            }

            img.Polygon1 {
              width: auto;
              height: 150px;
            }
          }

          .Hexa_Cont {
            height: 215px;
            width: 185px;
            background-size: auto 205px;

            .Hexa_content_Cont {
              .hexa_icon {
                width: auto;
                height: 55px;
              }

              .cate_name_text {
                font-size: 16px;
                width: 130px;
              }

              .hexa_img_block {
                padding-bottom: 5px;
              }
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 260px;
        width: 260px;
        top: -47px;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 250px;
      }
    }
  }

  // END
  .Why_Meeko {
    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 11%;
      }

      .Text_Container_02 {
        right: 10%;
        width: 45%;
        margin-top: 35px;
      }

      .Text_Container_03 {
        left: 13%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 10%;
        width: 46% !important;
      }

      .Text_Container_05 {
        left: 14%;
        position: relative;
      }

      .Text_Container_06 {
        right: 13%;
        margin-top: 100px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why3_Container {
      width: 270px;
      height: 270px;
    }

    .Why4_Container {
      width: 260px;
      height: 260px;

      .Why9 {
        left: 172px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Why_Meeko {
    .whiteflow1 {
      left: 7%;
    }

    .Why3_Container {
      width: 270px;
      height: 270px;
    }

    .Why4_Container {
      width: 260px;
      height: 260px;

      .Why9 {
        left: 172px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont {
    margin-top: -30px;

    .Round_Blue_Left {
      width: 260px;
      height: 270px;
      bottom: -13%;
    }

    .Round_Blue_Right {
      width: 150px;
      height: 310px;
    }

    .Become_Cont {
      padding: 40px 21px;
    }

    .Top_Cont {
      padding-bottom: 80px;
    }
  }

  .About_Meeko_Main_Container .Center_Text {
    // .Blue_Box {
    //     margin-top: 40px !important;
    // }
    .Pink_Box {
      margin-top: 100px !important;
    }

    .Orange_About_Box {
      margin-top: 50px !important;
    }
  }

  .Blue_Back_Container.try_demo_class_card_section,
  .Blue_Back_Container.popular_class_box_section {
    .Common_Card_Container {
      max-width: 295px;
    }
  }

  // HEADER

  .landing-header .header-alignment .logo img {
    width: 117px;
    height: 46px;
  }

  .landing-header .header-alignment .login {
    .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
    }

    .css-1392reu-control,
    .css-3xnrlj-control {
      width: 146px !important;
      height: 50px !important;
    }

    .css-1wa3eu0-placeholder {
      margin-left: 3px;
      font-size: 12px;
    }
  }

  .landing_header_scroll.landing-header .header-alignment {
    .logo {
      left: -137px;

      img {
        width: 114px;
        height: 50px;
      }
    }

    .menu nav ul li a.active_nav_Side {
      width: 151px;
      height: 45px;
    }
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li:last-child
    a {
    width: 151px;
    height: 45px;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 135px;
      height: 50px;
    }

    li a {
      font-size: 15px;
      margin-right: 5px;
      width: 165px;
      height: 50px;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 55px;
      margin-left: 4px;
      width: 124px;
    }

    .menu {
      margin-left: 149px;
    }

    .logo {
      left: -168px;
      width: 383px;

      // height: 383px;
      img {
        right: 25%;
        top: -48px;
      }
    }
  }

  .landing-header .header-alignment .menu nav ul li a {
    width: 133px;
    height: 50px;
  }

  .landing-header {
    padding: 20px;
  }

  .landing-header .header-alignment .menu {
    margin-left: 130px;
  }

  .landing-header.landing_header_scroll .header-alignment .menu {
    margin-left: 120px;
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    width: 109px;
    height: 109px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 104px;
    height: 104px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -14%;
    width: 190px;
    height: 190px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 100px;
    height: 100px;
    left: 10%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
    left: 0%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
  }

  // =============== WHY Is MEEKo =================================
  // img.Blue_Wawe {
  //   width: 162px;
  // }
  .Landing_Page_Blue_Background .Center_Video_Player video.Card_Img {
    // object-fit: contain;
    height: 505px;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 697px !important;
    height: 446px !important;
  }

  .Landing_Page_Blue_Background .Center_Video_Player {
    width: 72%;
    height: 510px;
    bottom: -17%;

    .child-image {
      top: -34%;
    }
  }

  .css-1392reu-control {
    padding-left: 5px !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
  }

  .Landing_Page_Blue_Background {
    top: -79px;

    .RIGHT_SIDE_IMG .Right_Box {
      width: 260px;
      top: 31%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -124px;
    }

    .RIGHT_SIDE_IMG {
      top: -50px;

      .paint1 {
        right: 55%;
        width: 86px;
        height: 87px;
        top: 40%;
      }

      .book {
        top: 30%;
        right: 74%;
        width: 57px;
        height: 70px;
      }

      .Star4 {
        top: 34%;
        right: 60%;
      }

      .Rocket {
        bottom: -45px;
        height: 337px;
        padding-left: 417px;
        width: 96%;
      }
    }
  }

  .Landing_Page_Blue_Background .Center_Video_Player .Center_Video iframe {
    width: 629px;
    height: 446px;
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 126px 0px 0px 0px;
    }

    .Center_Text .Blue_Box {
      margin-left: 0 !important;
      text-align: right;
      margin-top: 80px;
      margin-left: auto;
    }

    .Center_Text .Orange_About_Box .Details {
      font-size: 17px;
      font-weight: 400;
      max-width: 600px;
      color: #8484a0;
      margin: 0 0 1.25rem 0;
    }

    .Big_Round_Left {
      width: 337px;
      height: 337px;
      top: 19%;
      left: -15%;
    }

    .Illustration1 {
      right: -40%;
      top: 30%;
      height: 194px;
    }

    .white_Back {
      top: 27%;
      left: 52%;
      width: 220px;
    }

    .Big_Round_Bottom_Left {
      bottom: -14%;
      width: 346px;
      height: 346px;
      left: -138px;

      .Illustration2 {
        height: 175px;
        right: -30%;
        top: -8%;
      }

      .white_Back2 {
        left: 41%;
        top: -7%;
        width: 220px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 18%;
      right: 0;
      width: 206px;
      height: 371px;

      .Illustration3 {
        left: -41%;
        height: 232px;
        bottom: 15%;
        width: auto;
      }

      .white_Back3 {
        right: 31%;
        top: 27%;
        width: 220px;
      }
    }

    .child1 {
      position: absolute;
      top: 24%;
      right: 15%;
      width: 143px;
      height: 101px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: -11px;
        width: 172px;
      }

      .earth1 {
        top: 114px;
        width: 151px;
        left: 84px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 58%;
    }
  }

  // start TRY CLASSES FOR
  .Blue_Back_Container .Top_Cont {
    margin-top: -50px;
  }

  // end TRY CLASSES FOR
  // categories start
  .Categories {
    .Round_red {
      top: -25%;
      right: 5%;
    }

    .Paint_Bruch {
      bottom: 10%;
      left: 0%;
    }

    .shoes_boy {
      bottom: -117px;
    }
  }

  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 0 11px;

        .Hexa_content_Cont {
          margin: 6px 5px 8px 16px;

          p {
            color: #fff;
            font-size: 18px;
          }
        }

        .hexa {
          width: 72px;
        }

        .Hexagonal {
          width: 181px;
        }
      }
    }
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    .Hexagonal_image {
      margin: 5px;
      width: 252px;
      margin-right: -15px;
      margin-top: 35px;
      margin-left: -35px;
    }
  }

  .Categories .book_polygon {
    .Polygon1 {
      left: 41px;
      top: 0px;
      width: 113px;
    }

    .book {
      position: absolute;
      left: 72px;
      top: 43px;
      width: 55px;
    }

    .Polygon2 {
      position: relative;
      bottom: 109px;
      left: 0;
      width: 70px;
    }
  }

  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont {
    margin-top: -54px;
  }

  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 63px;
      height: 75px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }

    .Sub_Content_Container {
      width: 134px;

      .Round_Cont {
        width: 150px;
        height: 150px;
      }

      .Round_Cont img {
        width: 80px;
        height: 80px;
      }

      p {
        font-size: 15px;
        margin-top: 14px;
      }
    }
  }

  //end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 45%;

    p {
      font-size: 28px;
    }
  }

  .Why_Meeko {
    margin-bottom: 100px;

    .Top_Cont {
      margin-top: 50px;
    }

    .Why5 {
      left: 4%;
      height: 170px;
      bottom: 14%;
    }

    .whiteflow1 {
      left: 3%;
      top: 16%;
      width: 260px;
    }

    .whiteflow2 {
      left: 3%;
    }

    .whiteflow3 {
      right: -3%;
      top: 52%;
    }

    .Why6 {
      right: 0%;
      height: 197px;
      bottom: 24%;
    }

    .Why7 {
      right: 1%;
      height: 300px;
      bottom: -3%;
    }

    .Why3_Container {
      right: 4%;
      width: 230px;
      height: 230px;
      top: 33%;

      .Why3 {
        height: 195px;
      }

      .Why10 {
        left: -207px;
      }
    }

    .Why4_Container {
      width: 248px;
      height: 248px;

      .Why4 {
        height: 249px;
      }

      .Why9 {
        left: 77px;
        width: 100%;
        height: 61px;
        object-fit: scale-down;
      }

      .Why11 {
        top: 67%;
        left: 35%;
      }
    }

    .Why1 {
      height: 234px;
      left: 3%;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }
  }

  .Why_Meeko .Why2 {
    right: 28%;
  }

  // end why meeko
  // start BANNER
  .About_Banner .Text {
    width: 76%;
  }

  // end BANNER
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     gap: 0px 101px;
  //     .lendo-grid-items {
  //         .LendoImage {
  //             margin-left: 130px;
  //             width: 100%;
  //             height: 364px;
  //             object-fit: scale-down;
  //         }
  //         .Join_Btn {
  //             left: -4px;
  //             margin-top: 19px;
  //         }
  //     }
  // }
  .lendo-page-alignment {
    padding: 80px 20px 0;

    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;
      grid-template-columns: 530px minmax(0, 1fr);

      .lendo-grid-items {
        .icon-text-grid {
          margin-bottom: 0.7rem;
        }

        .Join_Btn {
          left: 40px;
          margin-top: 10px;
        }
      }

      img.LendoImage {
        width: auto;
        height: 300px;
        margin: auto 30px auto auto;
      }
    }
  }

  .lendo-page-alignment
    .lendo-grid
    .lendo-grid-items
    .icon-text-grid
    .icon-text-grid-items
    span {
    font-size: 19px !important;
  }

  // end LEADO
  // start BANNER
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    position: absolute;
    top: 20%;
    left: 7%;
    font-family: GothamRounded-Medium;
    color: white;

    h1 {
      font-size: 65px;
    }

    p {
      font-size: 45px;
      padding: 0 150px;
      width: 72%;
    }
  }

  // end BANNER
  //start SLIDER
  .Slider_Container_Main {
    .slick-prev i {
      left: 330px;
    }

    .slick-next i {
      right: 310px;
    }

    .slick-prev {
      display: flex !important;
      justify-content: center;
    }

    .slick-next {
      display: flex !important;
      justify-content: center;
    }

    .Slider_Box_Main {
      .Slider_Box {
        width: 761px;
        padding: 50px 29px;
      }

      .inner_Slider_Box {
        width: 636px !important;
      }

      .Slider_Box {
        .slick-slide::after {
          width: 613px;
          left: 7%;
        }

        .slick-slide::before {
          width: 601px;
          left: 8%;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        right: 1%;

        .profile2 {
          right: 81%;
        }
      }

      .right_slider_image {
        left: -7%;

        .profile5 {
          left: 86%;
        }
      }
    }
  }

  // end SLIDER
  // start FOOTER
  .landing-footer-alignment .footer-grid {
    margin-left: 0px;
  }

  .landing-footer-alignment .footer-grid {
    .footer-grid-items {
      &:nth-child(1) {
        margin-top: 26px;
      }

      .Social_Icon {
        width: 67%;
        margin-left: 43px;
        margin-right: -122px;
      }
    }

    .footer_second_col {
      width: 80%;

      ul {
        &::after {
          width: 80%;
        }
      }

      p {
        font-size: 15px;
        width: 70%;
      }

      ul {
        li {
          font-size: 18px !important;
          padding-left: 4px;
          padding-right: 4px;

          &:not(:last-of-type)::after {
            margin: 0px 2px;
          }
        }
      }
    }
  }

  // end FOOTER
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .Landing_Page_Blue_Background {
    @keyframes fly {
      0% {
        transform: translateY(50vh);
      }

      100% {
        transform: translateY(-125vh);
      }
    }

    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          width: 75%;
          height: 480px;
          margin: 0% auto;

          .Card_Img {
            height: 480px;
          }
        }
      }
    }
  }

  .back_to_top_btn {
    .top_btn {
      right: 20px;
      bottom: 30px;
      width: 50px;
      height: 50px;

      img {
        padding: 9px 9px;
      }
    }
  }

  .landing-header {
    .header-alignment {
      .login {
        .css-1392reu-control,
        .css-3xnrlj-control {
          width: 170px !important;
        }

        .css-1wa3eu0-placeholder {
          margin-left: 5px;
        }
      }
    }
  }

  .lendo-page-alignment .lendo_title {
    font-size: 30px;
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  .Categories.Categories_head_section {
    padding-top: 20px;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: -20px;
  }

  .Blue_Back_Container.Blue_Back_card_section {
    margin-top: -45px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 60px 80px 80px;
    background-position: 110% -15%;
    background-size: auto 920px;

    .Ultra_Pro_title_row {
      .Top_Cont {
        width: 558px;

        p {
          font-size: 45px;
        }
      }
    }

    .Ultra_Pro_content_row {
      grid-template-columns: 66% 34%;

      .Ultra_Pro_col_01 {
        grid-template-columns: 70% 30%;

        .Ultra_Pro_content_block {
          .Ultra_Pro_min_font {
            font-size: 30px;
          }

          .Ultra_Pro_big_title {
            font-size: 40px;
          }

          ul.Ultra_Pro_text li.Ultra_Pro_li {
            font-size: 20px;
            padding-bottom: 8px;
          }
        }
      }

      .Ultra_Pro_col_02 {
        .ultra_mobile_cell {
          width: auto;
          height: 570px;
        }

        img.ab_ultra_onlinelaunching {
          right: -11%;
          width: auto;
          height: 80px;
        }

        img.ab_ultra_play {
          right: -5%;
          width: auto;
          height: 55px;
        }

        img.ab_ultra_bell {
          left: -20%;
          width: auto;
          height: 85px;
        }
      }
    }
  }

  // END
  .popular_class_box_section {
    padding-top: 20px;
  }

  // NEW CATEGORY SECTION
  .Categories_main_section {
    img.Round_red {
      top: -3%;
      right: 5%;
    }

    .hexa_section {
      width: 1180px;

      .hexa_row {
        .hexa_col {
          .book_polygon {
            left: 8%;
            bottom: -25%;

            img.Polygon2 {
              top: -43%;
              left: 85%;
              width: auto;
              height: 100px;
            }

            img.Polygon1 {
              width: auto;
              height: 150px;
            }
          }

          .Hexa_Cont {
            height: 245px;
            width: 205px;
            background-size: auto 235px;

            .Hexa_content_Cont .hexa_icon {
              width: auto;
              height: 65px;
            }

            .Hexa_content_Cont .cate_name_text {
              font-size: 17px;
              width: 130px;
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 300px;
        width: 300px;
        top: -47px;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 285px;
      }
    }
  }

  // END
  .Why_Meeko {
    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 11%;
      }

      .Text_Container_02 {
        right: 8%;
      }

      .Text_Container_03 {
        left: 13%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: 10%;
        width: 46% !important;
      }

      .Text_Container_05 {
        left: 14%;
        position: relative;
      }

      .Text_Container_06 {
        right: 13%;
        margin-top: 100px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why3_Container {
      width: 270px;
      height: 270px;
    }

    .Why4_Container {
      width: 260px;
      height: 260px;

      .Why9 {
        left: 172px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont {
    .Become_Cont {
      .Content_Container_Main {
        .Next_arrow img {
          width: 30px;
          height: 28px;
          object-fit: contain;
        }
      }
    }
  }

  .Become_Meekonian_Cont {
    .Round_Blue_Left {
      width: 330px;
      height: 330px;
      bottom: -22%;
    }

    .Round_Blue_Right {
      width: 180px;
      height: 310px;
    }
  }

  .About_Meeko_Main_Container .Center_Text {
    .Blue_Box {
      margin-top: 60px !important;
    }

    .Pink_Box {
      margin-top: 100px !important;
    }
  }

  // HEADER
  .landing-header .header-alignment .logo img {
    width: 141px;
    height: 36px;
  }

  .landing-header .header-alignment .menu nav ul li a {
    font-size: 16px;
    width: 143px;
    height: 52px;
  }

  .landing_header_scroll.landing-header .header-alignment .logo img {
    width: 114px;
    height: 50px;
  }

  .landing_header_scroll.landing-header .header-alignment .logo {
    left: -172px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li
    a.active_nav_Side {
    width: 166px;
    height: 49px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li:last-child
    a {
    width: 166px;
    height: 49px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player {
    height: 524px;
    bottom: -15%;

    .child-image {
      top: -33.2%;
    }
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    width: 109px;
    height: 109px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 104px;
    height: 104px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -13%;
    width: 207px;
    height: 207px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 120px;
    height: 120px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 108px;
    height: 55px;
    left: 6%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 108px;
    height: 55px;
    bottom: 14%;
    right: 0%;
  }

  // ==============  Why Is Meeko =======
  // img.Blue_Wawe {
  //   width: 160px;
  //   height: 16px;
  // }
  .About_Meeko_Main_Container .Rocket1 {
    width: 98px;
  }

  .landing-header .header-alignment .logo img {
    bottom: 26%;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 707px !important;
    height: 446px !important;
  }

  .css-1392reu-control {
    padding-left: 5px !important;
  }

  .css-3xnrlj-control {
    padding-left: 5px !important;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 145px;
      height: 52px;
    }

    a {
      margin-right: 10px !important;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 48px;
      margin-left: 3px;
    }

    .menu {
      margin-left: 171px;
    }

    .logo {
      left: -164px;
    }
  }

  .Landing_Page_Blue_Background {
    top: -79px;

    .RIGHT_SIDE_IMG .Right_Box {
      top: 31%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -85px;
    }

    .RIGHT_SIDE_IMG {
      top: -50px;

      .paint1 {
        right: 52%;
        width: 100px;
        height: 100px;
        top: 40%;
      }

      .book {
        top: 31%;
        right: 73%;
        width: 63px;
        height: 86px;
        object-fit: contain;
      }

      .Star4 {
        top: 34%;
        right: 60%;
      }

      .Star5 {
        position: absolute;
        bottom: 58px;
        right: 5%;
        width: 20px;
      }

      .Rocket {
        bottom: -27px;
        padding-left: 458px;
        height: 379px;
        width: 95%;
      }
    }

    .Center_Video_Player {
      // width: 59%;
      .Center_Video iframe {
        width: 707px;
        height: 447px;
      }
    }
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 129px 0px 0px 0px;
    }

    .Center_Text .Blue_Box {
      margin-left: 0 !important;
      text-align: right;
      margin-top: 80px;
      margin-left: auto;
    }

    .Big_Round_Left {
      top: 0%;
      left: -12%;
      width: 373px;
      height: 373px;
    }

    .Illustration1 {
      right: -34%;
      top: 260px;
      height: 225px;
    }

    .white_Back {
      top: 75%;
      left: 52%;
      width: 239px;
    }

    .Big_Round_Bottom_Left {
      bottom: -143px;
      left: -13%;
      width: 378px;
      height: 378px;

      .Illustration2 {
        height: 213px;
        right: -44%;
        top: -10%;
      }

      .white_Back2 {
        left: 61%;
        top: 0%;
        width: 239px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 17%;
      right: 0;
      width: 216px;
      height: 391px;

      .Illustration3 {
        left: -78%;
        height: 263px;
        bottom: 32%;
      }

      .white_Back3 {
        right: 34%;
        top: 5%;
        width: 239px;
      }
    }

    .child1 {
      position: absolute;
      top: 24%;
      right: 15%;
      width: 133px;
      height: 106px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: 18px;
      }

      .earth1 {
        position: absolute;
        right: 0;
        top: 107px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 58%;
    }
  }

  // categories start
  .Categories {
    .Round_red {
      top: -25%;
      right: 5%;
    }

    .Paint_Bruch {
      bottom: 10%;
      left: 0%;
      width: 120px;
    }

    .shoes_boy {
      bottom: -117px;
    }
  }

  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 0 11px;

        .Hexa_content_Cont p {
          color: #fff;
          font-size: 18px;
        }

        .hexa {
          width: 72px;
        }

        .Hexagonal {
          width: 207px;
        }
      }
    }
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    .Hexagonal_image {
      margin: 5px;
      width: 252px;
      margin-right: -18px;
      margin-left: -19px;
      margin-top: 52px;
    }
  }

  .Categories .book_polygon {
    .Polygon1 {
      left: 41px;
      top: 0px;
      width: 113px;
    }

    .book {
      position: absolute;
      left: 72px;
      top: 43px;
      width: 55px;
    }

    .Polygon2 {
      position: relative;
      bottom: 128px;
      left: -4px;
      width: 70px;
    }
  }

  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 69px;
      height: 80px;
      top: -20px;
    }

    .Sub_Content_Container {
      width: 148px;

      .Round_Cont {
        width: 166px;
        height: 166px;
      }

      .Round_Cont img {
        width: 97px;
        height: 97px;
      }

      p {
        font-size: 18px;
        margin-top: 15px;
      }
    }
  }

  //end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 52%;

    p {
      font-size: 30px;
    }
  }

  .Why_Meeko {
    .Why5 {
      left: 9%;
      height: 217px;
    }

    .whiteflow2 {
      left: 5%;
    }

    .whiteflow3 {
      right: 0%;
      top: 50%;
    }

    .Why6 {
      right: 5%;
    }

    .Why7 {
      right: 7%;
    }

    .Why3_Container {
      right: 4%;
      width: 290px;
      height: 290px;

      .Why3 {
        height: 274px;
      }

      .Why10 {
        left: -250px;
      }
    }

    .Why4_Container {
      width: 268px;
      height: 268px;

      .Why4 {
        height: 260px;
      }

      .Why9 {
        left: 77px;
        width: 100%;
        height: 61px;
        object-fit: scale-down;
      }
    }

    .Why1 {
      height: 210px;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }
  }

  .Why_Meeko .Why2 {
    position: absolute;
    top: 16%;
    right: 23%;
    z-index: 100;
    height: 300px;
    object-fit: scale-down;
  }

  // end why meeko
  // start BANNER
  .About_Banner .Text {
    width: 76%;
  }

  // end BANNER
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     gap: 0px 101px;
  //     .lendo-grid-items {
  //         .LendoImage {
  //             margin-left: 130px;
  //             width: 95%;
  //         }
  //         .Join_Btn {
  //             left: -4px;
  //             margin-top: 23px;
  //         }
  //     }
  // }
  .lendo-page-alignment
    .lendo-grid
    .lendo-grid-items
    .icon-text-grid
    .icon-text-grid-items
    span {
    font-size: 21px !important;
  }

  .lendo-page-alignment {
    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;

      .lendo-grid-items .Join_Btn {
        left: 60px;
      }

      img.LendoImage {
        width: auto;
        height: 350px;
        margin: auto 30px auto auto;
      }
    }
  }

  // end LEADO
  // start BANNER
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    h1 {
      font-size: 71px;
    }

    p {
      font-size: 48px;
      padding: 0px 160px;
    }
  }

  // end BANNER
  // start slider
  .Slider_Container_Main {
    .Slider_Box_Main {
      .inner_Slider_Box {
        width: 746px !important;
      }

      .Slider_Box {
        .slick-slide::after {
          width: 732px;
          left: 7%;
        }

        .slick-slide::before {
          width: 716px;
          left: 8%;
        }
      }
    }

    .Slider_image {
      .left_slider_image {
        right: -27%;

        .profile2 {
          right: 81%;
        }
      }

      .right_slider_image {
        left: -43%;

        .profile5 {
          left: 86%;
        }
      }
    }
  }

  // end slider
  // start footer

  // .landing-footer-alignment {
  //   .footer-grid {
  //     margin-left: 30px;
  //     .footer_second_col {
  //       ul{
  //         &::after {

  //           width: 88%;

  //       }
  //       }

  //       p {
  //         width: 75%;
  //         line-height: 23px;
  //     }
  //     }
  //   }
  // }

  .landing-footer-alignment {
    .footer-grid {
      margin-left: 0px;

      .footer-grid-items {
        .Social_Icon {
          width: 91%;
          margin-left: 18px;
          margin-right: -95px;
        }
      }
    }

    .footer_second_col {
      p {
        width: 68%;
      }

      ul {
        &::after {
          width: 88% !important;
        }

        li {
          font-size: 21px !important;
          padding-left: 13px;
        }
      }
    }
  }

  // start footer
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .Landing_Page_Blue_Background {
    @keyframes fly {
      0% {
        transform: translateY(50vh);
      }

      100% {
        transform: translateY(-120vh);
      }
    }

    .Center_Video_Player {
      .Center_Video_Player_content {
        .Card_Image_Container {
          height: 559px;
        }
      }
    }
  }

  .try_demo_class_card_section {
    margin-top: -20px;

    .Common_Card_Container {
      .Card_Image_Container {
        height: 170px;
      }
    }
  }

  .Categories.Categories_head_section {
    padding-top: 30px;
  }

  .popular_class_box_section {
    padding-top: 20px;
  }

  .Become_Meekonian_Cont.Become_Meekonian_section {
    padding-top: 0;
    margin-top: 8px;
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION

  // END
  // NEW CATEGORY SECTION
  .Categories_main_section {
    img.Round_red {
      top: -3%;
      right: 5%;
    }

    .hexa_section {
      width: 1335px;

      .hexa_row {
        .hexa_col {
          .book_polygon {
            left: 8%;
            bottom: -25%;

            img.Polygon2 {
              top: -43%;
              left: 85%;
              width: auto;
              height: 100px;
            }

            img.Polygon1 {
              width: auto;
              height: 150px;
            }
          }

          .Hexa_Cont {
            height: 265px;
            width: 235px;
            background-size: auto 255px;

            .Hexa_content_Cont .hexa_icon {
              width: auto;
              height: 65px;
            }

            .Hexa_content_Cont .cate_name_text {
              font-size: 17px;
              width: 130px;
            }
          }
        }
      }

      .main_Hexa_content_Cont {
        height: 350px;
        width: 350px;
      }

      .hexa_main_row .hexa_main_col .Hexa_main_Cont {
        background-size: auto 330px;
      }
    }
  }

  // END

  .Why_Meeko {
    .why_Meeko_Container {
      .Text_Container_01 {
        position: relative;
        left: 11%;
      }

      .Text_Container_02 {
        right: 0%;
      }

      .Text_Container_03 {
        left: -1%;
        margin-top: 50px;
      }

      .Text_Container_04 {
        right: -2%;
      }

      .Text_Container_05 {
        left: 7%;
        position: relative;
      }

      .Text_Container_06 {
        right: 13%;
        margin-top: 100px;
      }
    }

    .whiteflow1 {
      left: 7%;
    }

    .Why3_Container {
      width: 270px;
      height: 270px;
    }

    .Why4_Container {
      width: 260px;
      height: 260px;

      .Why9 {
        left: 172px;
        width: 55px;
        height: 55px;
      }
    }
  }

  // -------
  .Become_Meekonian_Cont .Round_Blue_Left {
    width: 370px;
    height: 370px;
    bottom: -20%;
  }

  .Categories .Hexagonal_Container .Hexa_Cont {
    width: 16%;
    text-align: center;
  }

  .Categories .Hexagonal_Container {
    .Top_Hexagonal_Container {
      // width: 90%;
      .Hexa_Cont {
        // width: 18%;
      }
    }
  }

  .Categories .Hexagonal_Container {
    width: 100%;
  }

  .About_Meeko_Main_Container .Center_Text {
    .Blue_Box {
      margin-top: 60px !important;
    }

    .Pink_Box {
      margin-top: 100px !important;
    }
  }

  // HEADER
  .landing-header {
    .header-alignment {
      .menu {
        nav {
          ul {
            li {
              a {
                width: 161px;
                height: 55px;
              }
            }
          }
        }
      }
    }
  }

  .landing-header .header-alignment {
    .logo img {
      width: 157px;
      height: 39px;
      right: 23%;
    }

    .login {
      .css-1392reu-control {
        width: 180px !important;
      }

      .css-3xnrlj-control {
        width: 180px !important;
      }
    }
  }

  .landing-header .header-alignment .logo {
    left: -134px;
  }

  .landing_header_scroll.landing-header .header-alignment .logo img {
    width: 122px;
    height: 54px;
  }

  .landing_header_scroll.landing-header .header-alignment .logo {
    left: -172px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li
    a.active_nav_Side {
    width: 166px;
    height: 49px;
  }

  .landing_header_scroll.landing-header
    .header-alignment
    .menu
    nav
    ul
    li:last-child
    a {
    width: 166px;
    height: 49px;
  }

  // =======================  Banner
  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .music {
    width: 109px;
    height: 109px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .paint {
    width: 104px;
    height: 104px;
    object-fit: contain;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .Earth {
    left: -12%;
    width: 207px;
    height: 207px;
  }

  .Landing_Page_Blue_Background .LEFT_SIDE_IMG .camera {
    width: 120px;
    height: 120px;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::before {
    width: 138px;
    height: 69px;
    left: -6%;
  }

  .Landing_Page_Blue_Background .Center_Video_Player::after {
    width: 138px;
    height: 69px;
  }

  // ====================== What Is Meeko ==================
  // img.Blue_Wawe {
  //   width: 203px;
  // }
  .About_Meeko_Main_Container .Rocket1 {
    width: 125px;
    height: 111px;
  }

  .Landing_Page_Blue_Background
    .Center_Video_Player
    .Center_Video
    .react-player__preview {
    width: 797px !important;
    height: 446px !important;
  }

  .landing-header .header-alignment .menu nav ul {
    li a.active_nav_Side,
    li:last-child a {
      width: 162px;
      height: 55px;
    }
  }

  .landing-header .header-alignment {
    .login {
      margin-right: 67px;
      margin-left: 11px;
    }

    .menu {
      margin-left: 178px;
    }
  }

  .Landing_Page_Blue_Background {
    top: -79px;

    .RIGHT_SIDE_IMG .Right_Box {
      top: 31%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -85px;
    }

    .RIGHT_SIDE_IMG {
      top: -50px;

      .paint1 {
        right: 5%;
        width: 100px;
        height: 100px;
        top: 38%;
        left: 36%;
      }

      .book {
        top: 34%;
        right: 69%;
        width: 66px;
        height: 93px;
        object-fit: contain;
      }

      .Star4 {
        top: 34%;
        right: 60%;
      }

      .Star5 {
        bottom: 128px;
      }

      .Rocket {
        bottom: 24px;
        height: 338px;
        padding-left: 443px;
      }
    }

    .Center_Video_Player {
      // width: 59%;
      // height: 535px;
      bottom: -5%;
      width: 1030px;

      video.Card_Img {
        // height: 525px;
      }

      .child-image {
        top: -31%;
      }

      .Center_Video iframe {
        width: 797px;
        height: 447px;
      }
    }
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 129px 0px 0px 0px;
    }

    .Big_Round_Left {
      top: -5%;
      left: -12%;
      width: 457px;
      height: 457px;
    }

    .Illustration1 {
      right: -37%;
      top: 285px;
      height: 270px;
    }

    .white_Back {
      top: 72%;
      left: 62%;
      width: 251px;
    }

    .Big_Round_Bottom_Left {
      bottom: -125px;
      left: -14%;

      .Illustration2 {
        height: 256px;
        right: -35%;
        top: 98px;
      }

      .white_Back2 {
        left: 57%;
        top: 24%;
        width: 251px;
      }
    }

    .Big_Round_Bottom_Right {
      bottom: 20%;
      right: 0;
      width: 242px;
      height: 445px;

      .Illustration3 {
        left: -63%;
        height: 282px;
        bottom: 23%;
      }

      .white_Back3 {
        right: 38%;
        top: 20%;
        width: 251px;
      }
    }

    .child1 {
      position: absolute;
      top: 24%;
      right: 15%;
      width: 139px;
      height: 118px;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: -6px;
      }

      .earth1 {
        position: absolute;
        right: 0;
        top: 120px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 50%;
    }
  }

  // Try Classes for start
  .Blue_Back_Container .Card_Container {
    width: 88%;
  }

  .Common_Card_Container {
    width: 297px;
  }

  .SavedClass_Container {
    padding: 30px 8px;
  }

  // Try Classes for end
  // categories start
  .Categories {
    .Round_red {
      top: -25%;
      right: 5%;
    }

    .Paint_Bruch {
      bottom: 10%;
      left: 1%;
    }

    .shoes_boy {
      bottom: -117px;
    }
  }

  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 0 11px;

        .Hexa_content_Cont p {
          color: #fff;
          font-size: 18px;
        }

        .hexa {
          width: 76px;
        }

        .Hexagonal {
          width: 224px;
        }
      }
    }
  }

  .Categories .Hexagonal_Container .Top_Hexagonal_Container {
    .Hexagonal_image {
      width: 300px;
    }
  }

  .Categories .book_polygon {
    .Polygon1 {
      left: 41px;
      top: 0px;
      width: 113px;
    }

    .book {
      position: absolute;
      left: 72px;
      top: 43px;
      width: 55px;
    }

    .Polygon2 {
      position: relative;
      bottom: 128px;
      left: -4px;
      width: 70px;
    }
  }

  // categories end
  //start  Become a Meekonian today!
  .Become_Meekonian_Cont .Become_Cont .Content_Container_Main {
    .Next_arrow {
      width: 73px;
      height: 92px;

      img {
        width: 17px;
        height: 26px;
        object-fit: contain;
      }
    }

    // .Sub_Content_Container {
    //   width: 197px;
    //   .Round_Cont {
    //     width: 187px;
    //     height: 187px;
    //   }
    //   .Round_Cont img {
    //     width: 104px;
    //     height: 104px;
    //   }
    //   p {
    //     font-size: 16px;
    //   }
    // }
  }

  //end Become a Meekonian today!
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container {
    width: 52%;

    p {
      font-size: 35px;
    }
  }

  .Why_Meeko {
    .Why5 {
      left: 9%;
    }

    .whiteflow2 {
      left: 5%;
      top: 66%;
    }

    .whiteflow3 {
      top: 56%;
      right: 7%;
    }

    .Why3_Container {
      right: 4%;
    }

    .Why1 {
      height: 230px;
    }

    .Why12 {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 68%;
      left: 9%;
    }

    .Why_Meeko .why_Meeko_Container .Text_Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
    }
  }

  .Why_Meeko .Why2 {
    position: absolute;
    top: 16%;
    right: 23%;
    z-index: 100;
    height: 300px;
    object-fit: scale-down;
  }

  // end why meeko
  // start BANNER
  .About_Banner .Text {
    width: 70%;
  }

  // end BANNER
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     gap: 0px 101px;
  //     .lendo-grid-items {
  //         img {
  //             margin-left: 130px;
  //         }
  //         .Join_Btn {
  //             left: -4px;
  //         }
  //     }
  // }
  .lendo-page-alignment {
    .cus-container {
      width: 100%;
    }

    .lendo-grid {
      grid-gap: 0px;
      gap: 0px;

      .lendo-grid-items .Join_Btn {
        left: 60px;
      }

      img.LendoImage {
        width: auto;
        height: 350px;
        margin: auto 30px auto auto;
      }
    }
  }

  // end LEADO
  // start BANNER
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    h1 {
      font-size: 78px;
    }

    p {
      font-size: 50px;
      padding: 0px 160px;
    }
  }

  // end BANNER
  // start slider
  .Slider_Container_Main {
    .Slider_image {
      .left_slider_image {
        right: 35%;

        .profile2 {
          right: 81%;
        }
      }

      .right_slider_image {
        left: 27%;

        .profile5 {
          left: 86%;
        }
      }
    }
  }

  // end slider
  // start footer
  .footer_last_col {
    margin-right: -31px;
  }

  .landing-footer-alignment {
    .footer-grid {
      margin-left: 30px;

      .footer_second_col {
        ul {
          &::after {
            width: 88%;
          }
        }

        p {
          width: 75%;
          line-height: 23px;
        }
      }
    }
  }

  // end footer

  .Blue_Back_Container {
    .Common_Card_Container {
    }
  }

  .Common_Card_Main_Container .Common_Card_Container {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 1681px) and (max-width: 1880px) {
  .landing_header_scroll.landing-header {
    .header-alignment {
      .logo {
        img {
          width: 145px;
          height: 45px;
          right: 30%;
        }
      }
    }
  }

  .Blue_Back_Container.Blue_Back_card_section {
    .Common_Card_Container {
      max-width: 340px;
    }
  }

  // Ultra Pro convenient way to use our Platform | NEW SECTION
  .Ultra_Pro_section {
    padding: 100px 100px 140px;
  }

  // END
  // NEW CATEGORY SECTION
  .Categories_main_section .hexa_section {
    width: 1550px;

    .hexa_row {
      .hexa_col .Hexa_Cont {
        height: 295px;
        width: 255px;
        background-size: auto 290px;
      }
    }

    .main_Hexa_content_Cont {
      height: 350px;
      width: 350px;
    }

    .hexa_main_row .hexa_main_col .Hexa_main_Cont {
      background-size: auto 330px;
    }
  }

  // END
  // HEADER

  .landing-header .header-alignment {
    .menu {
      margin-left: 242px;
    }

    .login {
      margin-right: 81px;
      margin-left: 29px;
    }
  }

  .landing-header .header-alignment .logo {
    left: -93px;
  }

  .Landing_Page_Blue_Background {
    top: -84px;

    @keyframes fly {
      0% {
        transform: translateY(40vh);
      }

      100% {
        transform: translateY(-100vh);
      }
    }

    .RIGHT_SIDE_IMG .Right_Box {
      top: 32%;
      right: 2%;
    }

    .LEFT_SIDE_IMG {
      top: -85px;

      .music {
        top: 34%;
      }

      .paint {
        top: 35%;
        left: 66%;
      }
    }

    .RIGHT_SIDE_IMG {
      top: -50px;

      .Rocket {
        height: 364px;
        bottom: 28px;
      }

      // .paint1 {
      //   // right: -2%;
      // }
      .Star5 {
        bottom: 107px;
      }

      .Star6 {
        right: 33%;
      }
    }

    .Center_Video_Player {
      width: 59%;
      height: 537px;
      bottom: -14%;

      .Center_Video_Player_content {
        .Card_Image_Container {
          height: auto;
        }
      }

      .child-image {
        top: -32.5%;
      }

      video.Card_Img {
        width: 100%;
        // height: 528px;
      }

      .Center_Video {
        .react-player__preview {
          width: 980px !important;
          height: 447px !important;
        }

        iframe {
          height: 446px;
          width: 981px;
        }
      }
    }
  }

  .About_Meeko_Main_Container {
    .Center_Text .Top_About {
      margin: 129px 0px 0px 0px;
    }

    .Big_Round_Left {
      top: -9%;
      left: -9%;
    }

    .Illustration1 {
      right: -22%;
      top: 50%;
    }

    .white_Back {
      top: 50%;
      left: 45%;
    }

    .Big_Round_Bottom_Left {
      bottom: -111px;
      left: -15%;
    }

    .white_Back2 {
      left: 67%;
    }

    .Big_Round_Bottom_Right {
      bottom: 22%;
      right: 0;

      .Illustration3 {
        left: -60%;
      }

      .white_Back3 {
        right: 26%;
      }
    }

    .child1 {
      position: absolute;
      top: 24%;
      right: 15%;
    }

    .Earth_Round_Bottom_Right {
      .earthBack {
        position: relative;
        bottom: -6px;
      }

      .earth1 {
        position: absolute;
        right: 0;
        top: 120px;
      }

      .Rocket1 {
        position: absolute;
        bottom: -59px;
        right: 10%;
      }
    }

    .Compass {
      top: 58%;
    }
  }

  // Try Classes for start
  .SavedClass_Container {
    padding: 30px 5px;

    i {
      font-size: 15px;
    }
  }

  // Try Classes for end
  // categories start
  .Categories .Hexagonal_Container {
    .Hexa_Cont:nth-child(5) {
      margin-left: -7% !important;
    }

    .Top_Hexagonal_Container {
      .Hexagonal_for_media {
        margin: -21px 9px 0px 0px;
      }

      .Hexa_Cont {
        margin: 11px;

        .hexa {
          width: 78px;
        }

        .Hexagonal {
          width: 240px;
        }
      }
    }
  }

  .Categories .book_polygon {
    .Polygon1 {
      left: 66px;
      top: 32px;
    }

    .book {
      position: absolute;
      left: 104px;
      top: 81px;
    }

    .Polygon2 {
      position: relative;
      bottom: 146px;
      left: -15px;
    }
  }

  // categories end
  // start become a meekonian today
  // .Become_Meekonian_Cont
  //   .Become_Cont
  //   .Content_Container_Main
  //   .Sub_Content_Container {
  //   // width: 236px;
  //   .Round_Cont {
  //     width: 187px;
  //     height: 187px;
  //     img {
  //       width: 104px;
  //       height: 104px;
  //     }
  //   }
  // }
  // end become a meekonian today
  //start why meeko
  .Why_Meeko .why_Meeko_Container .Text_Container p {
    font-size: 35px;
  }

  .Why_Meeko {
    .Why5 {
      left: 13%;
    }

    .whiteflow2 {
      left: 10%;
    }

    .Why3_Container {
      right: 4%;
    }

    .Why1 {
      height: 249px;
    }
  }

  .Why_Meeko .Why2 {
    position: absolute;
    top: 16%;
    right: 31%;
    z-index: 100;
    height: 300px;
    object-fit: scale-down;
  }

  // end why meeko
  // start LEADO
  // .lendo-page-alignment .lendo-grid {
  //     gap: 0px 73px;
  //     .lendo-grid-items {
  //         margin-left: 54px;
  //         .Join_Btn {
  //             left: -100px;
  //         }
  //     }
  // }
  .lendo-page-alignment .cus-container {
    width: 81%;
  }

  // end LEADO
  // start BANNER
  .Banner_Container .Video_Banner .Item .banner .banner_content {
    h1 {
      font-size: 81px;
      font-weight: 100;
    }
  }

  // end BANNER
  // start slider
  .Slider_Container_Main {
    .Slider_image {
      .left_slider_image {
        right: 78%;
      }

      .right_slider_image {
        left: 83%;
      }
    }
  }

  // end slider
  .Why_Meeko .why_Meeko_Container {
    .Text_Container_02 {
      right: -10%;
    }

    .Text_Container_03 {
      left: -7%;
    }

    .Text_Container_04 {
      right: -8%;
    }

    .Text_Container_05 {
      left: 7%;
      position: relative;
    }
  }

  .landing-footer-alignment {
    .footer-grid {
      margin-left: 53px;

      .footer_second_col {
        ul {
          &::after {
            width: 86%;
          }
        }

        p {
          width: 76%;
        }
      }
    }
  }
}

@media screen and (min-width: 1881px) and (max-width: 1920px) {
  // .Landing_Page_Blue_Background .Center_Video_Player {
  //   // bottom: -16%;
  // }
}
