@font-face {
  font-family: Rubik-Regular;
  // src: url(../assets/fonts/Rubik/Rubik-Regular.ttf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Rubik/Rubik-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: Rubik-Medium;
  // src: url(../assets/fonts/Rubik/Rubik-Medium.ttf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Rubik/Rubik-Medium.ttf)
    format("truetype");
}

@font-face {
  font-family: GothamRounded-Bold;
  // src: url(../assets/fonts/gotham-rounded/GothamRounded-Bold.otf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Bold.otf)
    format("truetype");
}

@font-face {
  font-family: GothamRounded-Medium;
  // src: url(../assets/fonts/gotham-rounded/GothamRounded-Medium.otf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Medium.otf)
    format("truetype");
}

@font-face {
  font-family: Banda_Niera-Medium;
  // src: url(../assets/fonts/BandaNieraMedium/Banda_Niera-Medium.ttf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/BandaNieraMedium/Banda_Niera-Medium.ttf)
    format("truetype");
}

@font-face {
  font-family: Playball;
  // src: url(../assets/fonts/Playball-Regular/Playball-Regular.ttf) format("truetype");
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Playball-Regular/Playball-Regular.ttf)
    format("truetype");
}

@mixin heading-1 {
  font-size: 30px;
  line-height: 38px;
  font-family: Rubik-Medium !important;
  font-weight: 500;
}

@mixin large-heading {
  font-size: 62px;
  font-family: Rubik-Medium !important;
  font-weight: 400;
}

@mixin heading-2 {
  font-size: 25px;
  line-height: 35px;
  font-family: Rubik-Medium !important;
  font-weight: 500;
}

@mixin heading-8 {
  font-size: 40px;
  line-height: 50px;
  font-family: Rubik-Medium !important;
  font-weight: 500;
}

@mixin text-md {
  font-size: 20px;
  font-weight: 400;
  font-family: Rubik-Medium !important;
}

@mixin text-sm {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: Rubik-Medium;
}

@mixin text-small {
  font-size: 14px;
  line-height: 20px;
  font-family: Rubik-Medium !important;
  font-weight: 400;
}

@mixin text-normal {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.9px;
  font-weight: 400;
  font-family: Rubik-Medium !important;
}

@mixin text-xs {
  font-size: 12px;
  line-height: 18px;
  font-family: Rubik-Medium !important;
  font-weight: 400;
}

@mixin step-white-box {
  background-color: $primary_White;
  padding: 100px;
  border-radius: 16px;
}

@mixin heading-18 {
  font-size: 18px;
  line-height: 22px;
  font-family: GothamRounded-Medium;
  font-weight: 400;
}

@mixin heading-25 {
  font-family: Banda_Niera-Medium;
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

@mixin heading-60 {
  font-family: Banda_Niera-Medium;
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
}

@mixin heading-16 {
  font-size: 16px;
  line-height: 25px;
  font-family: GothamRounded-Medium;
  font-weight: 400;
}

@mixin heading-20 {
  font-size: 20px;
  line-height: 30px;
  font-family: GothamRounded-Medium;
  font-weight: 400;
}
