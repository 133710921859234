$primary_Back: #e4f9ff;
$Background_Color: #fcfcfc;
$Active_Button_Color: #efc623;
$primary_Blue: #34c4e1;
$primary_Pink: #ea57a9;
$primary_Purple: #ea57a9;
$primary_Green: #64e05d;
$primary_Light_Blue: #57c9e0;
$primary_Orange: #ea9a43;
$Primary_Font_Color: #959595;
$Font_Gray: #cdd1d3;
$Font_Dark: #555555;

$primary_black: #444;
$Nav_Font_Color: #1b0e4278;
$Sidebar_Font_Color: #444;
$Card_Color: #d7f6ff;
$Spaship: #96eeff;
$heading-color: #444;
$background: #f1f2f7;
$gray: #8f8f8f;
$light-gray: #bebebe;
$border-color: #eeeeee;
$light-blue: #e5f9ff;
$border-color-2: #9dddef;
$box-background: #f8f9fc;
$card-background: #f3f3f3;
$blue: #88dcf4;
$orange: #f8d583;
$orange-light: #fff5e3;
// $primary_Blue: #a2e3f7;
$gray: #c2c2c2;
$class_detaile_title: #8f8f8f;
$colorctwo: #c2c2c2;
$exploreClassMenu: #b8f3ff;
$login-h-color: #d1d1d1;

$light_grey: #808080a3;
$title-color: #8484a0;
$main-bgcolor: #eff0f6;
$purple-color: #aaa2f7;

$light_sky_blue: #a2e4f7;
$dark_sky_blue: #52adc7;
$blue_sky: #6dd2f3;
$yellow_dark: #efc623;
$yellow: #efc623;
$spanishgray: #9a9a9a;
$librablue: #4fd8ff;
$primaryBlack: #000;
// **********************************************

$lightblue: #2ebbda;
$Active_Button_Color: #efc623;
$primaryblue: #06add2;
$edu_yellow_active: #efc623;
$primary_White: #ffffff;
$title_color: #444;
$draft_class: #4be1a7;
$exploreClasstext: #5f92a1;
$lightskyblue: #94daeb;
$bordercolor: #c8f3ff;
$bgcolor: #effcff;
$blue: #7ed9f6;
$teachcolor: #0f3138;
$btncolor: #b6e7f6;
$fronttitle: #efc623;
$frontbg: #e6f1f7;
$titlecolor: #8483a2;
$category: #d3e3e5;
$learn_title: #8484a0;
